import React from "react";
import styles from "./PopupClube.module.css";
import AlertMaterial from "../../AlertMaterial.jsx";
import Loading from "../Loading.jsx";
import useFetch from "../../../Requisicao.jsx";
import { GlobalContext } from "../../GlobalContext.jsx";

const PopupClubes = ({ togglePopup, tipoVoto, clubes, idClubeSelect, onSelectClube }) => {

    const [mensagemUsuario, setMensagemUsuario] = React.useState("");
    const [tipoMensagemUsuario, setTipoMensagemUsuario] = React.useState("");

    const { error, loading, request } = useFetch();
    const { dados, setDados } = React.useContext(GlobalContext);

    const [filtro, setFiltro] = React.useState('');
    const [clubesFiltrados, setClubesFiltrados] = React.useState(clubes);

    const handleSelect = (idClube) => {
        const clubeSelecionado = clubes.find((clube) => clube._id === idClube);
        onSelectClube(idClube, tipoVoto);
    };

    const handleImageError = (e) => {
        e.target.src = '/img/clubes/000.webp';
    };

    // Função para remover acentos
    const removeAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    React.useEffect(() => {
        if (filtro === '') {
            setClubesFiltrados(clubes);
        } else {
            setClubesFiltrados(
                clubes.filter((clube) =>
                    removeAcentos(clube.nomeClube.toLowerCase()).includes(removeAcentos(filtro.toLowerCase()))
                )
            );
        }
    }, [filtro, clubes]);

    const clubesPorEstado = clubesFiltrados.reduce((acc, clube) => {
        const estado = clube.estadoNome;
        if (!acc[estado]) {
            acc[estado] = [];
        }
        acc[estado].push(clube);
        return acc;
    }, {});

    return (
        <>
            {mensagemUsuario && tipoMensagemUsuario && (
                <AlertMaterial tipo={tipoMensagemUsuario} mensagem={mensagemUsuario}/>
            )}

            {loading && <Loading/>}

            <div className={styles.popup}>
                <button className={styles.btnClose} onClick={() => togglePopup()} aria-label="Fechar Popup">
                    <i className="fa-solid fa-circle-xmark"></i>
                </button>
                <div className={styles.container}>
                    <div className={styles.search}>
                        <input
                            type="text"
                            placeholder="Pesquisar clubes..."
                            value={filtro}
                            onChange={(e) => setFiltro(e.target.value)}
                        />
                    </div>
                    <div className={styles.clubes}>
                        {Object.keys(clubesPorEstado).map((estado) => (
                            <div key={estado}>
                                <h3 className={styles.estado}>
                                    <span>{estado}</span>
                                </h3>
                                <ul>
                                    {clubesPorEstado[estado]
                                        .sort((a, b) => a.nomeClube.localeCompare(b.nomeClube))
                                        .map((clube) => {
                                            const nomeClubeFormatado = clube.nomeClube.substring(0, clube.nomeClube.lastIndexOf(' - '));
                                            return (
                                                <li key={clube.idClube}
                                                    onClick={() => handleSelect(clube._id)}
                                                    className={`${idClubeSelect === clube._id ? styles.selected : ''}`}>
                                                    <img width="35" height="35"
                                                         src={`/img/clubes/search/${clube.idClube}.webp`}
                                                         onError={handleImageError}
                                                         alt={nomeClubeFormatado}/>
                                                    <span>{nomeClubeFormatado}</span>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PopupClubes;
