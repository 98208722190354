import React from "react";
import styles from "./Loading.module.css";
const Loading = () => {
    return (
        <div className={styles.loading}>
            <img src={'/img/loading.gif'} alt={'loading'}/>
        </div>
    )
}

export default Loading