import React from "react";

const useFetch = () => {
    const [dados, setDados] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const request = React.useCallback(async (url, options) => {
        let response;
        let json;
        try {
            setError(null);
            setLoading(true);

            response = await fetch(url, options);
            // Verificando se a resposta é ok antes de tentar converter para JSON
            if (!response.ok) throw new Error('Erro na requisição');

            try {
                json = await response.json();
            } catch (err) {
                // Caso o corpo da resposta não seja um JSON válido
                throw new Error('Erro ao processar JSON');
            }

            setDados(json); // Definindo dados apenas quando a solicitação for bem-sucedida

        } catch (err) {
            json = null;
            setError(err.message);
        } finally {
            setLoading(false);
            // eslint-disable-next-line no-unsafe-finally
            return { response, json };
        }
    }, []);

    return {
        dados,
        loading,
        error,
        request
    };
};

export default useFetch;
