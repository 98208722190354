import React from 'react';

export const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
    const [dados, setDados] = React.useState({
        tipoRanking: 'amada',
    });

    return (
        <GlobalContext.Provider value={{ dados, setDados }}>
            {children}
        </GlobalContext.Provider>
    );
};