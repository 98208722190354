import React, {useContext} from "react";
import styles from "./PopupExcluirConta.module.css";
import {DELETE_TORCEDOR} from "../../../api.jsx";
import Loading from "../Loading.jsx";
import useFetch from "../../../Requisicao.jsx";
import {usuarioContext} from "../../UsuarioContext.jsx";

const PopupExcluirConta = ({togglePopup}) => {

    const { usuario, logoutUsuario } = useContext(usuarioContext);

    const [BTNFechar, setBTNFechar] = React.useState(false);
    const [excluiu, setExcluiu] = React.useState(false);
    const [avisoUsuario, setAvisoUsuario] = React.useState(
        <strong>Tem certeza que deseja excluir a sua conta?</strong>
    );
    const [mensagemUsuario, setMensagemUsuario] = React.useState(
        "Essa operação é irreversível e não poderá ser desfeita."
    );

    const { error, loading, request } = useFetch();

    const excluirConta = async () => {
        const { url, options } = DELETE_TORCEDOR(usuario._id);
        const { response, json } = await request(url, options);

        setBTNFechar(true);

        if (response.ok) {
            setExcluiu(true);
            setAvisoUsuario(
                <strong>Sua conta foi excluída com sucesso.</strong>
            );
            setMensagemUsuario("Esperamos que você volte a fazer parte da nossa comunidade novamente.");
        } else {
            setAvisoUsuario(
                <>
                    <span style={{color: 'red'}}>Não foi possível excluir a sua conta.</span>
                </>
            );
            setMensagemUsuario(
                <>
                    Entre em contato através do email <strong>contato@tamanhodastorcidas.com.br</strong>.<br />
                    <p>Ou tente novamente mais tarde.</p>.
                </>
            );
        }
    };

    return(
        <>
            {loading && <Loading/>}

            <div className={styles.popup}>
                {/*<button className={styles.btnClose} onClick={() => togglePopup(excluiu)}>*/}
                {/*    <i className="fa-solid fa-circle-xmark"></i>*/}
                {/*</button>*/}
                <div className={styles.container}>
                    <div className={styles.tituloPopup}>
                        <span><i className="fa-solid fa-circle-user"></i> EXCLUSÃO DE CONTA</span>
                    </div>
                    <div className={styles.msg}>
                        <span>{avisoUsuario}</span>
                        <span>{mensagemUsuario}</span>
                    </div>
                    <div className={styles.footerButton}>
                        {BTNFechar ?
                            (
                                <button onClick={() => togglePopup(excluiu)} aria-label="Fechar Popup">Fechar</button>
                            ) :
                            (<>
                                <button onClick={excluirConta}>Excluir conta</button>
                                <button onClick={() => togglePopup(excluiu)} aria-label="cancelar">Cancelar</button>
                            </>)

                        }
                    </div>

                </div>
            </div>
        </>

    )
}

export default PopupExcluirConta;