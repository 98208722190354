import ReactDOM from 'react-dom/client';
import WrappedApp from './WrappedApp.jsx';
import GoogleAdSense from "./components/GoogleADS/GoogleAdsense.jsx";

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
        <GoogleAdSense/>
        <WrappedApp />
    </>
);
