import React, { useEffect } from 'react';
import emailjs from 'emailjs-com';
import styles from "./Contato.module.css";
import Select from 'react-select';

const MAIL_SERVICE_ID = import.meta.env.VITE_MAIL_SERVICE_ID;
const MAIL_TEMPLATE_ID = import.meta.env.VITE_MAIL_TEMPLATE_ID;
const MAIL_USER_ID = import.meta.env.VITE_MAIL_USER_ID;

const options = [
    { value: 'Sugestão', label: 'Sugestão' },
    { value: 'Relatar Erro', label: 'Relatar Erro' },
    { value: 'Publicidade', label: 'Publicidade' },
    { value: 'Outros...', label: 'Outros...' }
];

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        marginBottom: '1em',
        padding: '0.1rem',
        fontSize: '1em',
        borderColor: state.isFocused ? '#343434' : '#ddd',
        boxShadow: state.isFocused ? '0 0 0 1px #343434' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#343434' : 'lightgrey',
        },
        borderRadius: '3px',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#7e7e7e' : state.isFocused ? '#f1f1f1' : null,
        color: state.isSelected ? '#fff' : '#343434',
        '&:active': {
            backgroundColor: '#343434',
            color: state.isFocused ? '#fff' : '#343434',
        },
        padding: 10,
        cursor: 'pointer',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#343434',
    }),
};

const Contato = () => {

    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        date: ''
    });

    const [status, setStatus] = React.useState([0, '']);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption) => {
        setFormData({
            ...formData,
            subject: selectedOption.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Obter a data e hora atuais
        const now = new Date();
        const formattedDate = now.toLocaleString(); // Formata a data e hora conforme a localidade do usuário

        // Adiciona a data ao formData
        const updatedFormData = { ...formData, date: formattedDate };

        try {
            const response = await emailjs.send(
                MAIL_SERVICE_ID,
                MAIL_TEMPLATE_ID,
                updatedFormData,
                MAIL_USER_ID
            );

            setStatus([true, 'Mensagem enviada com sucesso!']);
        } catch (error) {
            console.error('FALHOU...', error);
            setStatus([false, 'Falha no envio da mensagem.']);

            // Fechar mensagem de erro após 5 segundos
            setTimeout(() => {
                setStatus([0, '']);
            }, 5000);
        }

        // Clear the form
        setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            date: ''
        });
    };

    return (
        <div className={styles.container}>
            <h2>Contato</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <input
                    type="tel"
                    name="phone"
                    placeholder="Telefone"
                    value={formData.phone}
                    onChange={handleChange}
                />
                <Select
                    styles={customStyles}
                    options={options}
                    onChange={handleSelectChange}
                    placeholder="Selecione um assunto"
                    value={options.find(option => option.value === formData.subject)}
                    required={true}
                    autoComplete="off"
                />
                <textarea
                    name="message"
                    placeholder="Escreva sua mensagem..."
                    value={formData.message}
                    onChange={handleChange}
                    required
                />
                <input type="hidden" name="date" value={formData.date} />
                <button type="submit" aria-label="Enviar">ENVIAR</button>
            </form>
            {status[1] && <p className={status[0] === true ? styles.success : styles.fail}>{status[1]}</p>}
        </div>
    );
};

export default Contato;
