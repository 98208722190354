import styles from "../Info/Info.module.css";

const Termos = () => {
    return (
        <section className={styles.secao} id='top'>
            <div>
                <h2 style={{fontSize: '1rem', textAlign: 'center'}}>Segurança e Privacidade no Login</h2>
                <p className={styles.texto}>A segurança dos seus dados é nossa prioridade. Por isso, utilizamos autenticação por meio de OAuth,
                    uma tecnologia segura e amplamente adotada pelas maiores empresas do mundo, como Google, Facebook e
                    Microsoft.</p>

                <h3 className={styles.subtitulo}>Por que usar OAuth?</h3>
                <ul>
                    <li className={styles.texto}><strong>Segurança Avançada</strong>: OAuth elimina a necessidade de armazenarmos sua senha. Isso
                        significa que, mesmo em caso de tentativa de invasão, suas credenciais estarão seguras.
                    </li>
                    <li className={styles.texto}><strong>Privacidade</strong>: Armazenamos apenas seu nome e e-mail. Esses dados são usados
                        apenas para identificá-lo dentro da nossa plataforma e garantir a veracidade das informações
                        fornecidas.
                    </li>
                    <li className={styles.texto}><strong>Confiabilidade</strong>: Ao usar OAuth, você está utilizando os mesmos mecanismos de
                        segurança que protegem milhões de contas ao redor do mundo.
                    </li>
                </ul>

                <h3 className={styles.subtitulo}>Como Funciona?</h3>
                <p className={styles.texto}>Quando você escolhe uma das opções de login (Google, Facebook, Microsoft), você é redirecionado para
                    o site do provedor de sua escolha. Lá, você faz a autenticação e, uma vez autenticado, o provedor
                    nos informa que você é quem diz ser (envia-nos o seu nome e email), sem compartilhar sua senha conosco.</p>

                <h3 className={styles.subtitulo}>Transparência e Confiança</h3>
                <p className={styles.texto}>Nosso compromisso é garantir a sua segurança e privacidade. Se tiver qualquer dúvida, nossa equipe de
                    suporte está à disposição para ajudar.</p>
            </div>
        </section>
    )
}

export default Termos;