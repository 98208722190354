import styles from "./Info.module.css";

const Termos = () => {
    return (
        <section className={styles.secao} id='top'>
            <h1 className={`${styles.titulo} ${styles.termos}`}>Termos de Uso</h1>
            <br/>
            <span className={styles.texto}>
                <p className={styles.texto}>Bem-vindo ao <strong>Tamanho das Torcidas</strong>.
                Ao acessar e utilizar nosso site, você concorda com os seguintes Termos de Uso.
                Leia atentamente as condições abaixo. Caso não concorde com qualquer um dos termos, pedimos que não utilize o site.</p>

                <h3 className={styles.subtitulo}>1. Aceitação dos Termos</h3>
                <p className={styles.texto}>Ao criar uma conta e acessar nosso site, você concorda em cumprir e respeitar estes Termos de Uso, bem como nossa Política de Privacidade.</p>

                <h3 className={styles.subtitulo}>2. Cadastro e Autenticação</h3>
                <p className={styles.texto}>Para garantir a veracidade das informações coletadas, todos os usuários devem se cadastrar utilizando um e-mail válido ou autenticar-se através de OAuth (Google, Facebook, etc.).
                O fornecimento de informações falsas ou incorretas pode resultar na suspensão ou exclusão da conta.</p>

                <h3 className={styles.subtitulo}>3. Uso Permitido</h3>
                <p className={styles.texto}>Você concorda em utilizar o site exclusivamente para fins pessoais e não comerciais. É proibido:
                Usar o site de maneira que possa danificar, desativar, sobrecarregar ou comprometer nossos servidores ou redes.
                Tentar obter acesso não autorizado a qualquer parte do site, contas de outros usuários ou sistemas de computador ou redes conectadas ao nosso site.
                Utilizar o site para qualquer propósito ilegal ou não autorizado.</p>

                <h3 className={styles.subtitulo}>4. Conteúdo Gerado pelo Usuário</h3>
                <p className={styles.texto}>Você é responsável pelo conteúdo que publica ou compartilha no site.
                Ao enviar conteúdo, você garante que possui todos os direitos necessários para tal e concede ao <strong>Tamanho das Torcidas</strong> uma licença não exclusiva, irrevogável e livre de royalties para usar, reproduzir, modificar e distribuir esse conteúdo.</p>

                <h3 className={styles.subtitulo}>5. Propriedade Intelectual</h3>
                <p className={styles.texto}>Todos os direitos, títulos e interesses relacionados ao site, incluindo seu conteúdo, design, software e marcas registradas, são de propriedade exclusiva do <strong>Tamanho das Torcidas</strong> ou de seus licenciadores.
                Você não pode copiar, modificar, distribuir, vender ou alugar qualquer parte do nosso serviço ou software.</p>

                <h3 className={styles.subtitulo}>6. Privacidade</h3>
                <p className={styles.texto}>Nós levamos sua privacidade a sério. Leia nossa Política de Privacidade para entender como coletamos, usamos e protegemos suas informações pessoais.</p>

                <h3 className={styles.subtitulo}>7. Limitação de Responsabilidade</h3>
                <p className={styles.texto}>O <strong>Tamanho das Torcidas</strong> não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, consequenciais ou punitivos decorrentes de seu uso ou incapacidade de uso do site. Não garantimos que o site estará sempre disponível ou livre de erros.</p>

                <h3 className={styles.subtitulo}>8. Alterações nos Termos</h3>
                <p className={styles.texto}>Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento.
                Notificaremos os usuários sobre quaisquer mudanças significativas.
                O uso continuado do site após a publicação de alterações constitui aceitação dos novos termos.</p>

                <h3 className={styles.subtitulo}>9. Rescisão</h3>
                <p className={styles.texto}>Podemos encerrar ou suspender seu acesso ao site a qualquer momento, sem aviso prévio, se acreditarmos que você violou estes Termos de Uso.</p>

                <h3 className={styles.subtitulo}>10. Contato</h3>
                <p className={styles.texto}>Se você tiver qualquer dúvida sobre estes Termos de Uso, entre em contato conosco pelo e-mail <strong>contato@tamanhodastorcidas.com.br</strong>.
                Ao utilizar nosso site, você concorda com os Termos de Uso aqui descritos.
                    Obrigado por fazer parte do <strong>Tamanho das Torcidas</strong> e ajudar a construir uma comunidade de torcedores apaixonados.</p>

                <p>Atualizada em: 01/06/2024</p>
            </span>
        </section>
    )
}

export default Termos;