import styles from "./Clube.module.css";
import { useLocation } from "react-router-dom";
import React from "react";
import useFetch from "../../../Requisicao.jsx";
import { GlobalContext } from "../../GlobalContext.jsx";
import { GET_CLUBE } from "../../../api.jsx";
import AlertMaterial from "../../AlertMaterial.jsx";
import Loading from "../Loading.jsx";
import Adsense from "../GoogleADS/Adsense.jsx";
import { Progress, Table } from 'antd';
import Chart from 'react-apexcharts';

const Clube = ({ width, height }) => {
    const anuncio = import.meta.env.VITE_ANUNCIO === 'true';
    const location = useLocation();
    const queryString = location.search;

    const extractPosition = (search) => {
        const searchParams = new URLSearchParams(search);
        return searchParams.get('pos');
    };

    const extractId = (search) => {
        const searchParams = new URLSearchParams(search);
        return searchParams.keys().next().value; // Pega o primeiro parâmetro como ID
    };

    const [mensagemUsuario, setMensagemUsuario] = React.useState("");
    const [tipoMensagemUsuario, setTipoMensagemUsuario] = React.useState("");

    const { error, loading, request } = useFetch();
    const { dados, setDados } = React.useContext(GlobalContext);

    const [clube, setClube] = React.useState({});

    const buscarClube = async (idClube) => {
        const { url, options } = GET_CLUBE(idClube);
        const { response, json } = await request(url, options);

        if (response.ok) {
            setClube(json);
        } else {
            setMensagemUsuario("Não foi possível encontrar o clube.");
            setTipoMensagemUsuario("error");
            console.error("Erro ao carregar os dados do clube:", json ? json.message : 'Erro desconhecido');
        }
    };

    React.useEffect(() => {
        if (queryString) {
            const extractedId = extractId(queryString); // Extrai o ID corretamente
            buscarClube(extractedId);
        }
    }, [queryString]);

    const formatNumber = (num) => {
        if (num === undefined || num === null) {
            return '0';
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const handleImageError = (e) => {
        e.target.src = '/img/clubes/000.webp';
    };

    const calculatePercentage = (value, total) => {
        if (total === 0) return 0;
        return (value / total) * 100;
    };

    const malePercentage = calculatePercentage(clube?.contagem?.sexo?.M, clube?.contagem?.total);
    const femalePercentage = calculatePercentage(clube?.contagem?.sexo?.F, clube?.contagem?.total);

    const faixaEtaria = clube?.contagem?.faixaEtaria;
    const ageLabels = ["0 a 17", "18 a 29", "30 a 39", "40 a 49", "50 a 59", "60+"];
    const ageData = faixaEtaria ? Object.values(faixaEtaria).map(faixa => faixa.total) : [];

    const generateTreeData = (data, total) => {
        return data.map((item) => {
            const itemTotal = item.torcedores || 0;
            const percentage = calculatePercentage(itemTotal, total).toFixed(1);
            return {
                key: item.key,
                title: item.title,
                progress: (
                    <Progress
                        percent={parseFloat(percentage)}
                        showInfo={false}
                        strokeColor="#1890ff"
                    />
                ),
                text: `${itemTotal} (${percentage}%)`,
                children: item.children && item.children.length > 0 ? generateTreeData(item.children, total) : undefined
            };
        });
    };

    const treeData = clube?.contagem?.porLocalidade ? generateTreeData(clube.contagem.porLocalidade, clube.contagem.total) : [];

    const columns = [
        {
            title: 'Localidade',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '',
            dataIndex: 'progress',
            key: 'progress',
            width: '35%',
        },
        {
            title: 'Torcedores',
            dataIndex: 'text',
            key: 'text',
            width: '20%',
            align: 'right',
            className: "progressText"
        }
    ];

    const maxAgeData = Math.max(...ageData);

    const yAxisMax = Math.ceil(maxAgeData / 1) * 1; // Arredonda para o próximo múltiplo de 20

    return (
        <>
            {mensagemUsuario && tipoMensagemUsuario && (
                <AlertMaterial tipo={tipoMensagemUsuario} mensagem={mensagemUsuario} />
            )}

            {loading && <Loading />}

            <section className={styles.containerClube}>

                <div className={styles.cabecalho}>
                    <div className={styles.escudo}>
                        <img
                            src={`/img/clubes/${clube.idClube}.webp`}
                            className={styles.logo}
                            onError={handleImageError}
                            alt={`${clube.nomeClube} logo`}
                        />
                    </div>
                    <div className={styles.titulo}>
                        <div className={styles.nomeClube}>{clube.nomeClube}</div>
                        <div className={styles.torcedores}>
                            <span className={styles.posicaoNum}>{extractPosition(queryString)}ª</span>
                            <span className={styles.posicaoText}>POSIÇÃO:</span>
                            <span>{formatNumber(clube?.contagem?.total)}</span>
                            <img src='/img/favicon.png' />
                        </div>
                    </div>
                </div>

                <div className={styles.containerProgressbar}>
                    <div className={styles.progressBar}>
                        <div className={styles.progressMale} style={{width: `${malePercentage}%`}}></div>
                        <div className={styles.progressFemale}
                             style={{width: `${femalePercentage}%`, left: `${malePercentage}%`}}></div>
                    </div>

                    <div className={styles.progressLabels}>
                        <div>{`Masculino: ${clube?.contagem?.sexo?.M || 0} (${malePercentage.toFixed(1)}%)`}</div>
                        <div>{`Feminino: ${clube?.contagem?.sexo?.F || 0} (${femalePercentage.toFixed(1)}%)`}</div>
                    </div>
                </div>

                <div className={styles.chartContainer}>
                    <Chart
                        options={{
                            chart: {
                                type: 'bar',
                                toolbar: { show: false }
                            },
                            xaxis: {
                                categories: ageLabels
                            },
                            yaxis: {
                                max: yAxisMax, // Define o valor máximo do eixo Y
                                labels: {
                                    formatter: function (val) {
                                        return Math.floor(val) === val ? val : ''; // Garantir que apenas inteiros sejam exibidos
                                    }
                                }
                            }
                        }}
                        series={[{
                            name: 'Torcedores',
                            data: ageData
                        }]}
                        type="bar"
                        width="100%"
                        height="300"
                    />
                </div>

                <div className={"treeContainer"}>
                    <Table
                        columns={columns}
                        dataSource={treeData}
                        pagination={false}
                        rowKey="key"
                    />
                </div>

                {anuncio && <div className={styles.bannerPlub}>
                    <Adsense
                        client={import.meta.env.VITE_AD_CLIENT}
                        slot={import.meta.env.VITE_AD_SLOT}
                    />
                </div>}

            </section>
        </>
    )
}

export default Clube;
