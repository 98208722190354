import React from 'react';
import styles from './CoockieBanner.module.css';
import GoogleAnalytics from "./GoogleADS/GoogleAnalytics.jsx";

const setCookiePreference = (value, days) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    localStorage.setItem('cookieAccepted', JSON.stringify({ value, expires: expires.toISOString() }));
};

const getCookiePreference = () => {
    const cookiePreference = localStorage.getItem('cookieAccepted');
    if (!cookiePreference) return null;

    const { value, expires } = JSON.parse(cookiePreference);
    if (new Date() > new Date(expires)) {
        localStorage.removeItem('cookieAccepted');
        return null;
    }
    return value;
};

const CoockieBanner = ({ onAccept }) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const [cookiesAccepted, setCookiesAccepted] = React.useState(false);

    React.useEffect(() => {
        const cookiePreference = getCookiePreference();
        if (cookiePreference === null) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
            if (cookiePreference) {
                setCookiesAccepted(true);
                onAccept();
            }
        }
    }, [onAccept]);

    const handleAccept = () => {
        setCookiePreference(true, 365); // Armazena a preferência por 1 ano
        setIsVisible(false);
        setCookiesAccepted(true);
        onAccept();
    };

    const handleDecline = () => {
        setCookiePreference(false, 30); // Armazena a preferência por 30 dias
        setIsVisible(false);
    };

    return (
        <>
            {cookiesAccepted && <GoogleAnalytics />}
            <div className={`${styles.coockieBanner} ${isVisible ? styles.show : ''}`}>
                <div className={styles.containerMSG}>
                    <p>Nós usamos cookies.
                        Usamos ferramentas, como cookies, para habilitar serviços e funcionalidade essenciais
                        em nosso site e para coletar dados sobre como os visitantes interagem com nosso site.
                        Ao clicar em Aceitar, você concorda com o uso dessas ferramentas para publicidade, análise e
                        suporte.
                        <a href="/politica">Política de Privacidade</a></p>
                </div>
                <div className={styles.containerBTN}>
                    <button onClick={handleAccept} className={styles.aceitar} aria-label="Aceitar">Aceitar</button>
                    <button onClick={handleDecline} className={styles.declinar} aria-label="Declinar">Declinar</button>
                </div>
            </div>
        </>
    );
};

export default CoockieBanner;
