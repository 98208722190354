import styles from "./Info.module.css";

const SobreNos = () => {
    return(
        <div className={styles.secao} id='top'>
        <div className={`${styles.titulo} ${styles.sobre}`}>Sobre nós</div>
            <br/>
            <span className={styles.texto}>
            <p style={{textIndent: '20px'}}>Bem-vindo ao <strong>Tamanho das Torcidas</strong>, o primeiro portal dedicado a medir o verdadeiro tamanho das torcidas dos clubes de futebol brasileiro. Diferente de amostras limitadas, nosso site visa obter dados de milhões de torcedores em todo o país, proporcionando uma visão precisa e detalhada sobre a paixão pelo futebol em cada canto do Brasil.</p>

            <p style={{textIndent: '20px'}}> Nosso objetivo é oferecer uma plataforma transparente e confiável, onde cada torcedor pode expressar seu amor pelo clube do coração. Para garantir a veracidade das informações, todos os usuários precisam se autenticar via e-mail ou OAuth (conexão segura utilizando a conta do Facebook ou Instagram), assegurando que cada voto seja único e legítimo.</p>

            <p style={{textIndent: '20px'}}> No <strong>Tamanho das Torcidas</strong>, acreditamos que cada torcedor tem uma história para contar e uma paixão para compartilhar. Junte-se a nós e ajude a construir o maior e mais fiel retrato das torcidas brasileiras. Sua participação é fundamental para o sucesso deste projeto pioneiro.</p>

            <p style={{textIndent: '20px'}}> Explore, participe e faça parte desta grande comunidade de torcedores apaixonados. Estamos aqui para ouvir sua voz e celebrar a diversidade e a intensidade do futebol brasileiro.</p>

            <p  style={{textAlign: 'right', paddingRight: '20px'}}><strong>Tamanho das Torcidas</strong></p>
            </span>
        </div>
    )
}

export default SobreNos;