import styles from './Header.module.css';
import BannerTitle from "./BannerTitle.jsx";

const Header = ({ativo, width, height}) => {
    return(
        <div className={styles.containerHeader} style={{ boxShadow: ativo ? '0 5px 8px #777777' : ''}}>
            <BannerTitle/>
        </div>
    )
}

export default Header;
