import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleAnalytics = () => (
    <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z3NMXPPNWR"></script>
        <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-Z3NMXPPNWR', {
                page_path: window.location.pathname + window.location.search,
            });
        `}
        </script>
    </Helmet>
);

export default GoogleAnalytics;
