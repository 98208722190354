import React from "react";
import { POST_LOGIN, POST_VALIDA_TOKEN } from "../api.jsx";
import {useLocation, useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import procuraToken from "./procuraToken.jsx";

export const usuarioContext = React.createContext();

export const UsuarioStorage = ({ children }) => {
    const [locaisInicial, setLocaisInicial] = React.useState(null);
    const [usuario, setUsuario] = React.useState(null);
    const [logado, setLogado] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(0);
    const navigate = useNavigate();

    const logoutUsuario = () => {
        setUsuario(null);
        setLocaisInicial(null);
        setLoading(false);
        setLogado(false);
        window.localStorage.removeItem("token");
        Cookies.remove("token");
    };

    const navegarParaLogin = () => {
        const pathname = window.location.pathname;
        if(pathname === "/voto"){
            navigate("/login")
        } else {
            navigate(pathname)
        }
    };

    const navegarParaVoto = () => {
        const pathname = window.location.pathname;
        if(pathname === "/login"){
            navigate("/voto")
        } else {
            navigate(pathname)
        }
    };

    const buscarUsuario = async (token) => {
        const { url, options } = POST_VALIDA_TOKEN(token);
        const response = await fetch(url, options);

        if (response.ok) {
            const { usuario, locais } = await response.json();
            setUsuario(usuario);
            setLocaisInicial(locais);
            setLogado(true);
            navegarParaVoto();
        } else {
            setError(0);
            logoutUsuario();
            navegarParaLogin();
        }
    };


    const loginUsuario = async (email, senha, lembrarSenha) => {
        try {
            setError(0);
            setLoading(true);

            const { url, options } = POST_LOGIN({ email, senha });
            const response = await fetch(url, options);

            if (response.ok) {
                const { token } = await response.json();

                if (lembrarSenha) {
                    window.localStorage.setItem("token", token);
                } else {
                    window.localStorage.removeItem("token");
                    Cookies.set("token", token);
                }

                await buscarUsuario(token);
            } else {
                setError(2);
                logoutUsuario();
                navegarParaLogin();
            }
        } catch (err) {
            setError(1);
            setLogado(false);
            logoutUsuario();
            navegarParaLogin();
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        async function autologin() {
            const token = procuraToken();

            if (token) {
                try {
                    setError(0);
                    setLoading(true);

                    const { url, options } = POST_VALIDA_TOKEN(token);

                    const response = await fetch(url, options);

                    if (response.ok) {
                        const { usuario, locais } = await response.json();
                        setUsuario(usuario);
                        setLocaisInicial(locais);
                        setLogado(true);
                        navegarParaVoto();
                    } else {
                        setError(2);
                        logoutUsuario();
                        navegarParaLogin();
                    }
                } catch (err) {
                    setError(1);
                    logoutUsuario();
                    navegarParaLogin();
                } finally {
                    setLoading(false);
                }
            }
        }
        autologin();
    }, []);

console.log()
    return (
        <usuarioContext.Provider value={{ logado, usuario, error, loading, loginUsuario, logoutUsuario, setUsuario, setLogado, locaisInicial, setLocaisInicial }}>
            {children}
        </usuarioContext.Provider>
    );
};
