import React from "react";
import styles from './LinkNav.module.css';
import { Link } from 'react-router-dom';

const LinkNav = ({children, rota, classe}) => {
    return (
        <li className={`${styles.linkNav} ${classe ? styles[classe] : ''}`}>
            <Link to={rota}>
                {children}
            </Link>
        </li>
    );
}


export default LinkNav;
