import styles from "./Voto.module.css";
import React, { useState, useEffect, useContext } from "react";
import { usuarioContext } from "../../UsuarioContext.jsx";
import {
    GET_CLUBES_CONSULTA,
    GET_PAISES,
    GET_REGIOES,
    GET_ESTADOS,
    GET_CIDADES,
    PATCH_TORCEDOR
} from "../../../api.jsx";
import AlertMaterial from "../../AlertMaterial.jsx";
import Loading from "../Loading.jsx";
import useFetch from "../../../Requisicao.jsx";
import { GlobalContext } from "../../GlobalContext.jsx";
import PopupClubes from "./PopupClubes.jsx";
import { TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Select from 'react-select';
import PopupExcluirConta from "./PopupExcluirConta.jsx";
import axios from "axios";

const Voto = () => {
    const { usuario, logoutUsuario, setUsuario, locaisInicial } = useContext(usuarioContext);
    const [mensagemUsuario, setMensagemUsuario] = useState("");
    const [tipoMensagemUsuario, setTipoMensagemUsuario] = useState("");

    const { error, loading, request } = useFetch();
    const { dados, setDados } = useContext(GlobalContext);

    const [showPopup, setShowPopup] = useState({ visible: false, tipoVoto: null });
    const [showExcluirConta, setShowExcluirConta] = useState(false);
    const [clubesConsulta, setClubesConsulta] = useState([]);
    const [paises, setPaises] = useState([]);
    const [regioes, setRegioes] = useState([]);
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [selectedPais, setSelectedPais] = useState(null);
    const [selectedRegiao, setSelectedRegiao] = useState(null);
    const [selectedEstado, setSelectedEstado] = useState(null);
    const [selectedCidade, setSelectedCidade] = useState(null);

    const [selectedDay, setSelectedDay] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedSexo, setSelectedSexo] = useState(usuario.sexo ? usuario.sexo : "");

    const [isModified, setIsModified] = useState(false);
    const [isLoadingUser, setIsLoadingUser] = useState(true);

    const days = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: i + 1 }));
    const months = [
        { value: 1, label: "Janeiro" }, { value: 2, label: "Fevereiro" }, { value: 3, label: "Março" },
        { value: 4, label: "Abril" }, { value: 5, label: "Maio" }, { value: 6, label: "Junho" },
        { value: 7, label: "Julho" }, { value: 8, label: "Agosto" }, { value: 9, label: "Setembro" },
        { value: 10, label: "Outubro" }, { value: 11, label: "Novembro" }, { value: 12, label: "Dezembro" }
    ];
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 100 }, (_, i) => ({ value: currentYear - i, label: currentYear - i }));

    const handleLogoutUsuario = () => {
        logoutUsuario();
    }

    const routesToUpdate = [
        { "path": "/" },
        { "path": "/amada" },
        { "path": "/simpatizada" },
        { "path": "/odiada" },
        { "path": "/experiente" },
        { "path": "/masculina" },
        { "path": "/feminina" }
    ]

    const isDevelopment = !import.meta.env.VITE_NODE_ENV || import.meta.env.VITE_NODE_ENV === 'development';
    const urlSitemap = isDevelopment ? 'http://localhost:4000/update-sitemap' : 'https://www.tamanhodastorcidas.com.br/update-sitemap'

    const AtualizaRotasSiteMap = async (routesToUpdate) => {
        try {
            const response = await axios.post(urlSitemap, {
                routes: routesToUpdate
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response.data)
        } catch (error) {
            console.error('Error updating sitemap:', error);
        }
    };


    const consultaClubes = async () => {
        const { url, options } = GET_CLUBES_CONSULTA();
        const { response, json } = await request(url, options);

        if (response.ok) {
            setClubesConsulta(json);
        } else {
            setMensagemUsuario("Não foi possível carregar os clubes.");
            setTipoMensagemUsuario("error");
        }
    };

    const consultaPaises = async () => {
        const { url, options } = GET_PAISES();
        const { response, json } = await request(url, options);

        if (response.ok) {
            const paises = json.map(pais => ({ value: pais._id, label: pais.nome }));
            setPaises(paises);
            return paises;
        } else {
            setMensagemUsuario("Não foi possível carregar os países.");
            setTipoMensagemUsuario("error");
        }
    };

    const consultaRegioes = async (paisId) => {
        const { url, options } = GET_REGIOES(paisId);
        const { response, json } = await request(url, options);

        if (response.ok) {
            const regioes = json.map(regiao => ({ value: regiao.idRegiao, label: regiao.nome }));
            setRegioes(regioes);
            return regioes;
        } else {
            setMensagemUsuario("Não foi possível carregar as regiões.");
            setTipoMensagemUsuario("error");
        }
    };

    const consultaEstados = async (regiaoId) => {
        const { url, options } = GET_ESTADOS(regiaoId);
        const { response, json } = await request(url, options);

        if (response.ok) {
            const estados = json.map(estado => ({ value: estado.idEstado, label: estado.nome }));
            setEstados(estados);
            return estados;
        } else {
            setMensagemUsuario("Não foi possível carregar os estados.");
            setTipoMensagemUsuario("error");
        }
    };

    const consultaCidades = async (estadoId) => {
        const { url, options } = GET_CIDADES(estadoId);
        const { response, json } = await request(url, options);

        if (response.ok) {
            const cidades = json.map(cidade => ({ value: cidade.idCidade, label: cidade.nome }));
            setCidades(cidades);
            return cidades;
        } else {
            setMensagemUsuario("Não foi possível carregar as cidades.");
            setTipoMensagemUsuario("error");
        }
    };

    const atualizaUsuario = async () => {
        try {
            const paisValue = selectedPais ? selectedPais.value : null;
            const regiaoValue = selectedRegiao ? selectedRegiao.value : null;
            const estadoValue = selectedEstado ? selectedEstado.value : null;
            const cidadeValue = selectedCidade ? selectedCidade.value : null;
            const dayValue = selectedDay ? selectedDay.value : null;
            const monthValue = selectedMonth ? selectedMonth.value : null;
            const yearValue = selectedYear ? selectedYear.value : null;

            if (!dayValue || !monthValue || !yearValue || !selectedSexo || !selectedPais || !selectedRegiao || !selectedEstado || !selectedCidade) {
                setMensagemUsuario("Por favor, preencha todos os campos MEUS DADOS.");
                setTipoMensagemUsuario("error");
                return;
            }

            if (!usuario.clubeFavorito || !usuario.clubeSimpatiza || !usuario.clubeRival) {
                setMensagemUsuario("Por favor, preencha todos os SEUS VOTOS.");
                setTipoMensagemUsuario("error");
                return;
            }

            const body = {
                token: "l5XTy7,:v,+>1OwcI9Udb<8$_MSC2j8SYn;;ei)pLu)NzgE",
                nome: usuario.nome,
                email: usuario.email,
                sexo: selectedSexo,
                dataNascimento: `${yearValue}-${monthValue}-${dayValue}`,
                pais: paisValue,
                regiao: regiaoValue,
                estado: estadoValue,
                cidade: cidadeValue,
                clubeFavorito: usuario.clubeFavorito,
                clubeSimpatiza: usuario.clubeSimpatiza,
                clubeRival: usuario.clubeRival
            }

            const { url, options } = PATCH_TORCEDOR(body, usuario._id);

            const { response, json } = await request(url, options);

            if (response.ok) {
                setUsuario(json.usuario)
                setMensagemUsuario("Dados registrados com sucesso.");
                setTipoMensagemUsuario("success");
                setIsModified(false);

                AtualizaRotasSiteMap(routesToUpdate);
            } else {
                setMensagemUsuario("Erro ao atualizar os dados.");
                setTipoMensagemUsuario("error");
            }
        } catch (error) {
            setMensagemUsuario("Erro ao atualizar os dados.");
            setTipoMensagemUsuario("error");
        }
    };

    const carregarEstadosECidades = (regiaoId) => {

        if (!locaisInicial || !locaisInicial.regioes) {
            return [];
        }

        const regiao = locaisInicial.regioes.find(r => r.idRegiao === regiaoId);
        if (regiao) {
            const estados = regiao.estados.map(estado => ({
                value: estado.idEstado,
                label: estado.nome,
                cidades: estado.cidades
            }));
            return estados;
        }
        return [];
    };

    useEffect(() => {
        const carregarDadosUsuario = async () => {
            await consultaClubes();
            const paises = await consultaPaises();

            if (usuario) {
                const [year, month, day] = usuario.dataNascimento.split('-');
                setSelectedDay(days.find(d => d.value === parseInt(day, 10)));
                setSelectedMonth(months.find(m => m.value === parseInt(month, 10)));
                setSelectedYear(years.find(y => y.value === parseInt(year, 10)));
                setSelectedSexo(usuario.sexo);

                const paisSelecionado = paises.find(p => p.value === usuario.pais);
                setSelectedPais(paisSelecionado);

                if (paisSelecionado) {
                    const regioes = paisSelecionado ? await consultaRegioes(paisSelecionado.value) : [];
                    const regiaoSelecionada = regioes.find(r => r.value === usuario.regiao);
                    setSelectedRegiao(regiaoSelecionada);

                    if (regiaoSelecionada) {
                        const estados = await carregarEstadosECidades(regiaoSelecionada.value);
                        setEstados(estados);
                        const estadoSelecionado = await estados.find(e => e.value === usuario.estado);
                        setSelectedEstado(estadoSelecionado);

                        if (estadoSelecionado) {
                            const cidades = estadoSelecionado.cidades.map(cidade => ({
                                value: cidade.idCidade,
                                label: cidade.nome
                            }));
                            setCidades(cidades);
                            const cidadeSelecionada = cidades.find(c => c.value === usuario.cidade);
                            setSelectedCidade(cidadeSelecionada);
                        }
                    }
                }
            }
        };

        carregarDadosUsuario();
        setIsLoadingUser(false);
    }, []);

    useEffect(() => {
        if (!isLoadingUser && selectedPais){
            consultaRegioes(selectedPais.value);
            setSelectedRegiao(null);
            setSelectedEstado(null);
            setSelectedCidade(null);
            setEstados([]);
            setCidades([]);
        }
    }, [selectedPais]);

    useEffect(() => {
        if (!isLoadingUser && selectedRegiao){
            consultaEstados(selectedRegiao.value);

            if(selectedRegiao.value !== usuario.regiao){
                setSelectedEstado(null);
                setSelectedCidade(null);
                setCidades([]);
            }
        }
    }, [selectedRegiao]);

    useEffect(() => {
        if (!isLoadingUser && selectedEstado) {
            consultaCidades(selectedEstado.value);

            if(selectedEstado.value !== usuario.estado){
                setSelectedCidade(null);
            }
        }
    }, [selectedEstado]);

    useEffect(() => {
        if (mensagemUsuario && tipoMensagemUsuario) {
            const timer = setTimeout(() => {
                setMensagemUsuario("");
                setTipoMensagemUsuario("");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [mensagemUsuario, tipoMensagemUsuario]);

    const handleDateChange = (type, value) => {
        if (type === "day") setSelectedDay(value);
        if (type === "month") setSelectedMonth(value);
        if (type === "year") setSelectedYear(value);
        setIsModified(true);
    };

    const handleFieldChange = (setter) => (value) => {
        setter(value);
        setIsModified(true);
    };

    const togglePopup = (idClube, tipoVoto) => {
        setShowPopup({ visible: !showPopup.visible, tipoVoto });
    };

    const toggleExcluirConta = (excluiu) => {
        setShowExcluirConta(!showExcluirConta);

        if(excluiu){
            logoutUsuario();
        }
    };

    const handleSelectClube = (idClube, tipoVoto) => {
        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            [tipoVoto]: idClube,
        }));
        setIsModified(true);
        setShowPopup({ visible: false, tipoVoto: null });
    };

    const handleImageError = (e) => {
        e.target.src = '/img/clubes/000.webp';
    };

    const filtraClubesSelecionados = (tipoVoto) => {
        const clubesSelecionados = [
            usuario.clubeFavorito,
            usuario.clubeSimpatiza,
            usuario.clubeRival
        ];
        return clubesConsulta.filter(clube => clube._id !== usuario[tipoVoto] && !clubesSelecionados.includes(clube._id));
    };

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#343434' : '#c2c2c2',
            boxShadow: state.isFocused ? '0 0 0 1px #343434' : 'none',
            '&:hover': {
                borderColor: state.isFocused ? '#343434' : 'lightgrey',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#565656' : state.isFocused ? '#f1f1f1' : null,
            color: state.isSelected ? '#fff' : '#343434',
            '&:active': {
                backgroundColor: '#343434',
                color: state.isFocused ? '#fff' : '#343434',
            },
            padding: 10,
            cursor: 'pointer',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#252525',
        }),
    };

    return (
        <>
            {mensagemUsuario && tipoMensagemUsuario && (
                <AlertMaterial tipo={tipoMensagemUsuario} mensagem={mensagemUsuario}/>
            )}

            {loading && <Loading/>}

            <h1 className={styles.titulo}>
                <span>CADASTRO</span>
            </h1>

            <div className={styles.logout}>
                <button onClick={handleLogoutUsuario} aria-labelledby="desconectar">
                    <span id="desconectar">DESCONECTAR</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                </button>
            </div>

            <h2 className={styles.subtitulo}>
                <span>MEUS DADOS</span>
            </h2>

            <div className={styles.dados}>
                <TextField label="Nome" value={usuario.nome} disabled fullWidth margin="normal"/>
                <TextField label="Email" value={usuario.email} disabled fullWidth margin="normal"/>

                <label>Data de Nascimento: </label>
                <div className={styles.dataNascimento}>
                    <Select
                        styles={selectStyles}
                        className={styles.dia}
                        value={selectedDay}
                        onChange={(value) => handleDateChange("day", value)}
                        options={days}
                        placeholder="Dia"
                    />
                    <Select
                        styles={selectStyles}
                        className={styles.mes}
                        value={selectedMonth}
                        onChange={(value) => handleDateChange("month", value)}
                        options={months}
                        placeholder="Mês"
                    />
                    <Select
                        styles={selectStyles}
                        className={styles.ano}
                        value={selectedYear}
                        onChange={(value) => handleDateChange("year", value)}
                        options={years}
                        placeholder="Ano"
                    />
                </div>

                <div className={styles.genero}>
                    <label>Gênero: </label>
                    <RadioGroup row value={selectedSexo}
                                onChange={(e) => handleFieldChange(setSelectedSexo)(e.target.value)} required>
                        <FormControlLabel value="M" control={<Radio/>} label="Masculino"/>
                        <FormControlLabel value="F" control={<Radio/>} label="Feminino"/>
                    </RadioGroup>
                </div>

                <div className={styles.local}>
                    <label>País: </label>
                    <Select
                        styles={selectStyles}
                        value={selectedPais}
                        onChange={(value) => handleFieldChange(setSelectedPais)(value)}
                        options={paises}
                        placeholder="Selecione o País"
                        autoComplete="off"
                    />

                    <label>Região: </label>
                    <Select
                        styles={selectStyles}
                        value={selectedRegiao}
                        onChange={(value) => handleFieldChange(setSelectedRegiao)(value)}
                        options={regioes}
                        placeholder="Selecione a Região"
                        autoComplete="off"
                    />

                    <label>Estado: </label>
                    <Select
                        styles={selectStyles}
                        value={selectedEstado}
                        onChange={(value) => handleFieldChange(setSelectedEstado)(value)}
                        options={estados}
                        placeholder="Selecione o Estado"
                        autoComplete="off"
                    />

                    <label>Cidade: </label>
                    <Select
                        styles={selectStyles}
                        value={selectedCidade}
                        onChange={(value) => handleFieldChange(setSelectedCidade)(value)}
                        options={cidades}
                        placeholder="Selecione a Cidade"
                        autoComplete="off"
                    />
                </div>
            </div>

            <h2 className={styles.subtitulo}>
                <span>MEUS VOTOS</span>
            </h2>

            <div className={styles.dados}>
                <div className={styles.clubButtons}>
                    {["clubeFavorito", "clubeSimpatiza", "clubeRival"].map((tipoVoto) => (
                        <div key={tipoVoto}>
                            {tipoVoto === "clubeFavorito" &&
                                <div className={styles.tituloVotoClube}>O MEU CLUBE DO CORAÇÃO<i
                                    className="fa-solid fa-heart" style={{color: 'red'}}></i>
                                </div>}
                            {tipoVoto === "clubeSimpatiza" &&
                                <div className={styles.tituloVotoClube}>O CLUBE QUE ME SIMPATIZO<i
                                    className="fa-solid fa-face-smile"
                                    style={{color: "#f6d70a"}}></i></div>}
                            {tipoVoto === "clubeRival" && <div className={styles.tituloVotoClube}>O MEU MAIOR RIVAL<i
                                className="fa-solid fa-face-angry"
                                style={{color: 'purple'}}></i></div>}
                            <button
                                onClick={() => togglePopup(usuario[tipoVoto], tipoVoto)}
                                className={styles.buttonVoto}
                            >
                                <div className={styles.voto}>
                                    <img
                                        src={`/img/clubes/${clubesConsulta.find(clube => clube._id === usuario[tipoVoto])?.idClube || "000"}.webp`}
                                        onError={handleImageError}
                                        alt={tipoVoto}/>
                                    <span>{clubesConsulta.find(clube => clube._id === usuario[tipoVoto])?.nomeClube || "Selecionar Clube"}</span>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
                <button
                    className={`${styles.buttonSalvar} ${!isModified ? styles.buttonSalvarDisable : ''}`}
                    onClick={atualizaUsuario}
                    disabled={!isModified}
                >
                    SALVAR
                </button>
                <button
                    className={styles.buttonExcluirConta}
                    onClick={() => toggleExcluirConta(false)}
                >
                    Desejo excluir minha conta
                </button>
            </div>

            {showPopup.visible && (
                <PopupClubes
                    togglePopup={togglePopup}
                    clubes={filtraClubesSelecionados(showPopup.tipoVoto)}
                    tipoVoto={showPopup.tipoVoto}
                    idClubeSelect={usuario[showPopup.tipoVoto]}
                    onSelectClube={handleSelectClube}
                />
            )}
            {showExcluirConta && (
                <PopupExcluirConta
                    togglePopup={toggleExcluirConta}
                />
            )}
        </>
    );
}

export default Voto;
