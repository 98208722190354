import React from "react";
import "./resetCSS.css";
import "../public/fonts/css/all.min.css";
import "./App.css";
import { Routes, Route, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

import {GlobalProvider} from "./GlobalContext.jsx";

import NotFound from "./components/NotFound/NotFound.jsx";
import Home from "./components/Home/Home.jsx";
import ScrollToTopButton from "./components/ScrollTopButton.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import {UsuarioStorage} from "./UsuarioContext.jsx";
import CoockieBanner from "./components/CoockieBanner.jsx";


const usePageTracking = () => {
    const location = useLocation();

    React.useEffect(() => {
        const cookiePreference = JSON.parse(localStorage.getItem('cookieAccepted'));
        if (cookiePreference && cookiePreference.value) {
            if (window.gtag) {
                window.gtag('config', 'G-Z3NMXPPNWR', {
                    page_path: location.pathname + location.search,
                });
            }
        }
    }, [location]);
};


function App() {

    const handleAcceptCookies = () => {
        localStorage.setItem('cookieAccepted', JSON.stringify({ value: true, expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString() }));

        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-Z3NMXPPNWR`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', 'G-Z3NMXPPNWR');
        };
    };

    React.useEffect(() => {
        const cookiePreference = JSON.parse(localStorage.getItem('cookieAccepted'));
        if (cookiePreference && cookiePreference.value) {
            // Este efeito agora inicializa o Google Analytics com o script gtag.js
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=G-Z3NMXPPNWR`;
            script.async = true;
            document.head.appendChild(script);

            script.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    window.dataLayer.push(arguments);
                }
                window.gtag = gtag;
                gtag('js', new Date());
                gtag('config', 'G-Z3NMXPPNWR');
            };
        }
    }, []);

    usePageTracking();

    return (
        <ConfigProvider  locale={ptBR}>
            <UsuarioStorage>
                <GlobalProvider>
                    <ScrollToTop />
                        <Routes>
                            <Route path="/*" element={<Home/>} />
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    <ScrollToTopButton/>
                    <CoockieBanner onAccept={handleAcceptCookies} />
                </GlobalProvider>
            </UsuarioStorage>
        </ConfigProvider>
    )
}

export default App;
