import React from "react";
import styles from './LinkNavBTN.module.css';
import { Link } from 'react-router-dom';

const LinkNavBTN = ({children, rota, onClick, classeTamanho, tooltip = null}) => {

    const liClassName = `${styles.linkNavBTN} ${styles[classeTamanho]}`;

    return (
        <li className={liClassName} onClick={onClick}>
            <Link to={rota} className={styles.link}>
                {children}
                {tooltip && <span className={styles.tooltip}>{tooltip}</span>}
                {tooltip && <span className={styles.sr_only}>{tooltip}</span>}
            </Link>
        </li>
    )
}

export default LinkNavBTN;
