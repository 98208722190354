import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const AlertMaterial = ({ tipo, mensagem }) => {
    const [msg, setMsg] = React.useState(mensagem);
    const [open, setOpen] = React.useState(true);
     function SlideTransition(props) {
         return <Slide {...props} direction="down"/>;
     }
     const handleClose = (event, reason) => {
             if (reason === 'clickaway') {
                 return;
             }

             setOpen(false);
         };

         return (
             <div>
                 <Snackbar
                     anchorOrigin={{ vertical: "top", horizontal: "center" }}
                     open={open}
                     autoHideDuration={6000}
                     onClose={handleClose}
                     TransitionComponent={SlideTransition}
                     message="Mensagem do sistema"
                     key={SlideTransition}
                 >
                     <Alert
                         onClose={handleClose}
                         severity={tipo}
                         //variant="filled"
                         sx={{ width: '100%' }}
                     >
                         {msg}
                     </Alert>
                 </Snackbar>
             </div>
         );
     }

     export default AlertMaterial