import React from "react";
import styles from './Home.module.css';
import {GlobalContext} from "../../GlobalContext.jsx";
import Adsense from "../GoogleADS/Adsense.jsx";
import Body from "../Body.jsx";
import useWindowSize from "../../useWindowSize.jsx";

const Home = () => {
    const { width, height } = useWindowSize();
    const {dados, setDados} = React.useContext(GlobalContext);
    const anuncio = import.meta.env.VITE_ANUNCIO === 'true';

    return (
        <div className={styles.containerPage}>

            {width > 1360 ? anuncio && <div className={styles.columnPlub}>
                <Adsense
                    client={import.meta.env.VITE_AD_CLIENT}
                    slot={import.meta.env.VITE_AD_SLOT}
                />
            </div> : ''}

            <div className={styles.container}>

            <Body width={width} height={height}/>

            </div>

            {width > 1040 ? anuncio && <div className={styles.columnPlub}>
                <Adsense
                    client={import.meta.env.VITE_AD_CLIENT}
                    slot={import.meta.env.VITE_AD_SLOT}
                />
            </div> : ''}
        </div>
    )
}

export default Home;