import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleAdSense = () => (
    <Helmet>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    </Helmet>
);

export default GoogleAdSense;
