import React from "react";
import Cookies from "js-cookie";

function procuraToken() {

    let token = window.localStorage.getItem("token");
    let tokenCookies = Cookies.get("token");

    if (!token && !tokenCookies) {
        return null; // Retorna null se não houver token disponível
    }

    if(!token) {
        token = tokenCookies; // Usa o token do cookies
    }

    return token; // Retorna o token se estiver disponível
}

export default procuraToken;