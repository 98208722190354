import React from "react";
import styles from "./LinkNav.module.css";

const ExternalLink = ({ children, rota, nome }) => {
    return (
        <li className={styles.linkNav}>
            <a href={rota} target="_blank" rel="noopener noreferrer">
                {children}
                {nome && <span className={styles.sr_only}>{nome}</span>}
            </a>
        </li>
    );
};

export default ExternalLink;
