import React from "react";
import styles from './Body.module.css';
import Header from "./Header.jsx";
import {Navigate, Routes, Route, useLocation, useNavigate} from "react-router-dom";

import {GlobalContext} from "../GlobalContext.jsx";
import Filtros from "./Filtros/Filtros.jsx";
import Ranking from "./Ranking/Ranking.jsx";
import LinkNavBTN from "./LinkNavBTN.jsx";
import Clube from "./Clube/Clube.jsx";
import Info from "./Info/Info.jsx";
import SobreNos from "./Info/SobreNos.jsx";
import Termos from "./Info/Termos.jsx";
import Votar from "./Voto/Voto.jsx";
import Footer from "./Footer.jsx";
import Politica from "./Info/Politica.jsx";
import Contato from "./Info/Contato.jsx";
import NotFound from "./NotFound/NotFound.jsx";
import RotaProtegida from "../../RotaProtegida.jsx";
import Login from "./Login/Login.jsx";

const Body = ({width, height}) => {

    const [ativaSombra, setAtivaSombra] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const {dados, setDados} = React.useContext(GlobalContext);

    React.useEffect(() => {

        if(dados.tipoRanking === "filtros" || location.pathname === "/login" || location.pathname === "/filtros"){
            setAtivaSombra(true);
            setIsVisible(true);
        } else {
            setAtivaSombra(false);
            setIsVisible(false);
        }

    }, [dados.tipoRanking, location.pathname]);


    const handleTipoRanking = (tipo) => {

        setDados(prevState => ({
                ...prevState,
                tipoRanking: tipo,
            })
        )

        navigate("/"+tipo)
    }



    return (
        <div className={styles.containerBody}>

            <Header ativo={ativaSombra} width={width} height={height} />
            <Routes>
                <Route path="/login" element={<Login isVisible={isVisible}/>}/>
                <Route path="/filtros" element={<Filtros/>}/>
            </Routes>
            <main
                className={`${styles.principal} ${ativaSombra ? styles.sombra : ''}`}
            >
                <>
                    <div className={styles.containerLinks}>
                        {location.pathname === '/filtros' ?
                            (<LinkNavBTN rota={"/personalizado"}  classeTamanho={width < 425 ? 'large' : 'mediumVotar'} onClick={() => handleTipoRanking("personalizado")}><i className="fa-solid fa-ranking-star"></i> RANKING</LinkNavBTN>) : width < 400 ?
                            (
                              <nav style={{display: 'flex', flexDirection: 'column', margin: 'auto'}}>
                                    <ul>
                                        <LinkNavBTN rota={"/amada"}
                                                    onClick={() => handleTipoRanking("amada")}
                                                    tooltip={width > 1040 ? "+AMADA" : null}
                                                    classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-heart"></i>{width < 550 ? " +AMADA" : ''}
                                        </LinkNavBTN>
                                        <LinkNavBTN rota={"/simpatizada"}
                                                    onClick={() => handleTipoRanking("simpatizada")}
                                                    tooltip={width > 1040 ? "+SIMPATIZADA" : null}
                                                    classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-face-smile"></i>{width < 550 ? " +SIMPATIZADA" : ''}
                                        </LinkNavBTN>
                                    </ul>

                                    <ul style={{borderTop: '1px solid lightgrey'}}>
                                        <LinkNavBTN rota={"/odiada"}
                                                    onClick={() => handleTipoRanking("odiada")}
                                                    tooltip={width > 1040 ? "+ODIADA" : null}
                                                    classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-face-angry"></i>{width < 550 ? " +ODIADA" : ''}
                                        </LinkNavBTN>
                                        <LinkNavBTN rota={"/experiente"}
                                                    onClick={() => handleTipoRanking("experiente")}
                                                    tooltip={width > 1040 ? "+EXPERIENTE" : null}
                                                    classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-clock"></i>{width < 550 ? " +EXPERIENTE" : ''}
                                        </LinkNavBTN>
                                    </ul>

                                    <ul style={{borderTop: '1px solid lightgrey'}}>
                                        <LinkNavBTN rota={"/masculina"}
                                                    onClick={() => handleTipoRanking("masculina")}
                                                    tooltip={width > 1040 ? "+MASCULINA" : null}
                                                    classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-mars"></i>{width < 550 ? " +MASCULINA" : ''}
                                        </LinkNavBTN>
                                        <LinkNavBTN rota={"/feminina"} onClick={() => handleTipoRanking("feminina")}
                                                    tooltip={width > 1040 ? "+FEMININA" : null}
                                                    classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-venus"></i>{width < 550 ? " +FEMININA" : ''}
                                        </LinkNavBTN>
                                    </ul>

                                    <ul style={{borderTop: '1px solid lightgrey'}}>
                                        <LinkNavBTN rota={"/filtros"} onClick={() => handleTipoRanking("filtros")}
                                                    tooltip={width > 1040 ? "FILTRAR" : null}
                                                    classeTamanho={width < 600 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-magnifying-glass"></i>{width < 550 ? " FILTRAR" : ''}
                                        </LinkNavBTN>
                                        {width < 550 ? (<LinkNavBTN rota={"/voto"} onClick={() => handleTipoRanking("Voto")}
                                                                    classeTamanho={width < 550 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                            <i className="fa-solid fa-check-to-slot"></i> VOTAR</LinkNavBTN>) : ''}
                                    </ul>
                              </nav>
                            ) : width < 550 ?
                                    (
                                        <nav style={{display: 'flex', flexDirection: 'column', margin: 'auto'}}>
                                            <ul>
                                                <LinkNavBTN rota={"/amada"}
                                                            onClick={() => handleTipoRanking("amada")}
                                                            tooltip={width > 1040 ? "+AMADA" : null}
                                                            classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-heart"></i>{width < 550 ? " +AMADA" : ''}
                                                </LinkNavBTN>
                                                <LinkNavBTN rota={"/simpatizada"}
                                                            onClick={() => handleTipoRanking("simpatizada")}
                                                            tooltip={width > 1040 ? "+SIMPATIZADA" : null}
                                                            classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-face-smile"></i>{width < 550 ? " +SIMPATIZADA" : ''}
                                                </LinkNavBTN>
                                                <LinkNavBTN rota={"/odiada"}
                                                            onClick={() => handleTipoRanking("odiada")}
                                                            tooltip={width > 1040 ? "+ODIADA" : null}
                                                            classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-face-angry"></i>{width < 550 ? " +ODIADA" : ''}
                                                </LinkNavBTN>
                                            </ul>

                                            <ul style={{borderTop: '1px solid lightgrey'}}>
                                                <LinkNavBTN rota={"/experiente"}
                                                            onClick={() => handleTipoRanking("experiente")}
                                                            tooltip={width > 1040 ? "+EXPERIENTE" : null}
                                                            classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-clock"></i>{width < 550 ? " +EXPERIENTE" : ''}
                                                </LinkNavBTN>
                                                <LinkNavBTN rota={"/masculina"}
                                                            onClick={() => handleTipoRanking("masculina")}
                                                            tooltip={width > 1040 ? "+MASCULINA" : null}
                                                            classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-mars"></i>{width < 550 ? " +MASCULINA" : ''}
                                                </LinkNavBTN>
                                                <LinkNavBTN rota={"/feminina"}
                                                            onClick={() => handleTipoRanking("feminina")}
                                                            tooltip={width > 1040 ? "+FEMININA" : null}
                                                            classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-venus"></i>{width < 550 ? " +FEMININA" : ''}
                                                </LinkNavBTN>
                                            </ul>

                                            <ul style={{borderTop: '1px solid lightgrey', margin: 'auto'}}>
                                                <LinkNavBTN rota={"/filtros"}
                                                            onClick={() => handleTipoRanking("filtros")}
                                                            tooltip={width > 1040 ? "FILTRAR" : null}
                                                            classeTamanho={width < 600 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-magnifying-glass"></i>{width < 550 ? " FILTRAR" : ''}
                                                </LinkNavBTN>
                                                {width < 550 ? (<LinkNavBTN rota={"/voto"}
                                                                            onClick={() => handleTipoRanking("voto")}
                                                                            classeTamanho={width < 550 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-check-to-slot"></i> VOTAR</LinkNavBTN>) : ''}
                                            </ul>
                                        </nav>
                                    ) :
                                    (
                                        <ul>
                                            <LinkNavBTN rota={"/amada"}
                                                        onClick={() => handleTipoRanking("amada")}
                                                        tooltip={width > 1040 ? "+AMADA" : null}
                                                        classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                <i className="fa-solid fa-heart"></i>{width < 550 ? " +AMADA" : ''}
                                            </LinkNavBTN>
                                            <LinkNavBTN rota={"/simpatizada"}
                                                        onClick={() => handleTipoRanking("simpatizada")}
                                                        tooltip={width > 1040 ? "+SIMPATIZADA" : null}
                                                        classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                <i className="fa-solid fa-face-smile"></i>{width < 550 ? " +SIMPATIZADA" : ''}
                                            </LinkNavBTN>
                                            <LinkNavBTN rota={"/odiada"}
                                                        onClick={() => handleTipoRanking("odiada")}
                                                        tooltip={width > 1040 ? "+ODIADA" : null}
                                                        classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                <i className="fa-solid fa-face-angry"></i>{width < 550 ? " +ODIADA" : ''}
                                            </LinkNavBTN>
                                            <LinkNavBTN rota={"/experiente"}
                                                        onClick={() => handleTipoRanking("experiente")}
                                                        tooltip={width > 1040 ? "+EXPERIENTE" : null}
                                                        classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                <i className="fa-solid fa-clock"></i>{width < 550 ? " +EXPERIENTE" : ''}
                                            </LinkNavBTN>

                                            <LinkNavBTN rota={"/masculina"}
                                                        onClick={() => handleTipoRanking("masculina")}
                                                        tooltip={width > 1040 ? "+MASCULINA" : null}
                                                        classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                <i className="fa-solid fa-mars"></i>{width < 550 ? " +MASCULINA" : ''}
                                            </LinkNavBTN>
                                            <LinkNavBTN rota={"/feminina"} onClick={() => handleTipoRanking("feminina")}
                                                        tooltip={width > 1040 ? "+FEMININA" : null}
                                                        classeTamanho={width < 425 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                <i className="fa-solid fa-venus"></i>{width < 550 ? " +FEMININA" : ''}
                                            </LinkNavBTN>

                                            <LinkNavBTN rota={"/filtros"} onClick={() => handleTipoRanking("filtros")}
                                                        tooltip={width > 1040 ? "FILTRAR" : null}
                                                        classeTamanho={width < 600 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                <i className="fa-solid fa-magnifying-glass"></i>{width < 550 ? " FILTRAR" : ''}
                                            </LinkNavBTN>
                                            {width < 550 ? (
                                                <LinkNavBTN rota={"/voto"} onClick={() => handleTipoRanking("voto")}
                                                            classeTamanho={width < 550 ? 'large' : width < 560 ? 'medium' : 'small'}>
                                                    <i className="fa-solid fa-check-to-slot"></i> VOTAR</LinkNavBTN>) : ''}
                                        </ul>
                                    )
                        }
                        {width >= 550 || location.pathname === '/filtros' ? (<ul>
                            <LinkNavBTN rota={"/voto"} onClick={() => handleTipoRanking("voto")}
                                        classeTamanho={width < 425 ? 'large' : 'mediumVotar'}>
                                <i className="fa-solid fa-check-to-slot"></i> VOTAR</LinkNavBTN>
                        </ul>) : ''}
                    </div>
                </>
                <Routes>
                    <Route path="/" element={<Ranking tipoRanking="amada"  width={width} height={height}/>} />
                    <Route path="/amada" element={<Ranking tipoRanking="amada"  width={width} height={height}/>} />
                    <Route path="/simpatizada" element={<Ranking tipoRanking="simpatizada"  width={width} height={height}/>} />
                    <Route path="/odiada" element={<Ranking tipoRanking="odiada"  width={width} height={height}/>} />
                    <Route path="/experiente" element={<Ranking tipoRanking="experiente"  width={width} height={height}/>} />
                    <Route path="/masculina" element={<Ranking tipoRanking="masculina"  width={width} height={height}/>} />
                    <Route path="/feminina" element={<Ranking tipoRanking="feminina"  width={width} height={height}/>} />
                    <Route path="/personalizado" element={<Ranking tipoRanking="personalizado"  width={width} height={height}/>} />
                    <Route path="/clube" element={<Clube width={width} height={height}/>}/>
                    <Route path="/voto" element={<RotaProtegida element={<Votar width={width} height={height}/>}/>}/>
                    <Route path="/sobre" element={<SobreNos/>}/>
                    <Route path="/termos" element={<Termos/>}/>
                    <Route path="/politica" element={<Politica/>}/>
                    <Route path="/contato" element={<Contato/>}/>
                    <Route path="/filtros" element={<></>}/>
                    <Route path="/login" element={<></>}/>
                    <Route path="*" element={<NotFound replace />} />
                </Routes>
            </main>
            <Info width={width} height={height}/>
            <Footer />
        </div>
    )
}

export default Body;
