import React from "react";
import {Navigate} from "react-router-dom";
import {usuarioContext} from "./src/UsuarioContext.jsx";

const RotaProtegida = ({ element }) => {
    //verifica se o contexto do usuário está logado
    const {logado} = React.useContext(usuarioContext);

    //se estiver logado aceita o endereço, caso contrário redireciona para login
    return logado ? element : <Navigate to={"/login"} />
}
export default RotaProtegida