import React from 'react';
import { Button, Result } from 'antd';
import styles from './NotFound.module.css';


const NotFound = () => {

    // Função para navegar para a página inicial
    const goToHome = () => {
        window.location.href = '/'; // Utilize '/' como o caminho para a página inicial
    };

    return (
        <Result
            status="404"
            title="Página não encontrada."
            subTitle="A página que você tentou acessar não existe."
            extra={<Button className={styles.buttonHome} onClick={goToHome} aria-label="Voltar para Home">VOLTAR PARA HOME</Button>}
        />
    );
};

export default NotFound;
