import styles from "./Info.module.css";

const Politica = () => {
    return (
        <section className={styles.secao} id='top'>
            <h1 className={`${styles.titulo} ${styles.politica}`}>Política de Privacidade</h1>
            <br/>
            <span className={styles.texto}>
                <p className={styles.texto}>No <strong>Tamanho das Torcidas</strong>, valorizamos a privacidade de nossos usuários e estamos comprometidos em proteger suas informações pessoais.
                    Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos seus dados, em conformidade com a <strong>Lei Geral de Proteção de Dados Pessoais (LGPD) – Lei nº 13.709/2018</strong>.</p>


                <h3 className={styles.subtitulo}>1. Informações Coletadas</h3>
                <p>Coletamos informações pessoais que você nos fornece diretamente ao criar uma conta e usar nossos serviços, incluindo:</p>
                    <ul className={styles.lista}>
                        <li className={styles.texto}>Nome</li>
                        <li className={styles.texto}>E-mail</li>
                        <li className={styles.texto}>Data de nascimento</li>
                        <li className={styles.texto}>Gênero</li>
                        <li className={styles.texto}>Localização (estado e cidade)</li>
                    </ul>

                    <br/>
                    <p>Também podemos coletar informações automaticamente através do uso de cookies e tecnologias semelhantes, incluindo:</p>
                        <ul className={styles.lista}>
                            <li className={styles.texto}>Endereço IP</li>
                            <li className={styles.texto}>Tipo de navegador</li>
                            <li className={styles.texto}>Páginas visitadas</li>
                            <li className={styles.texto}>Tempo de navegação</li>
                        </ul>

                <h3 className={styles.subtitulo}>2. Uso das Informações</h3>
                <p>Utilizamos suas informações pessoais para os seguintes fins:</p>
                    <ul className={styles.lista}>
                        <li className={styles.texto}>Criar e gerenciar sua conta</li>
                        <li className={styles.texto}>Validar a autenticidade dos votos e garantir a veracidade dos dados</li>
                        <li className={styles.texto}>Fornecer suporte ao usuário e responder a consultas</li>
                        <li className={styles.texto}>Personalizar sua experiência no site</li>
                        <li className={styles.texto}>Analisar e melhorar nossos serviços</li>
                        <li className={styles.texto}>Cumprir obrigações legais e regulatórias</li>
                    </ul>

                <h3 className={styles.subtitulo}>3. Compartilhamento de Informações</h3>
                <p>Não compartilhamos suas informações pessoais com terceiros, exceto:</p>
                    <ul className={styles.lista}>
                        <li className={styles.texto}>Com provedores de serviços que auxiliam na operação do site, sob obrigações de confidencialidade</li>
                        <li className={styles.texto}>Quando exigido por lei, para cumprir com obrigações legais ou regulatórias</li>
                        <li className={styles.texto}>Em caso de fusão, aquisição ou venda de ativos, onde as informações do usuário podem ser transferidas</li>
                    </ul>

                <h3 className={styles.subtitulo}>4. Armazenamento e Segurança</h3>
                <p className={styles.texto}>Armazenamos suas informações pessoais em servidores seguros e adotamos medidas técnicas e organizacionais adequadas para proteger seus dados contra acesso não autorizado, perda ou destruição.
                Os dados sensíveis são criptografados para garantir sua segurança e confidencialidade.</p>

                <h3 className={styles.subtitulo}>5. Direitos dos Titulares dos Dados</h3>
                <p>De acordo com a LGPD, você tem o direito de:</p>
                    <ul className={styles.lista}>
                        <li className={styles.texto}>Confirmar a existência de tratamento de dados</li>
                        <li className={styles.texto}>Acessar seus dados pessoais</li>
                        <li className={styles.texto}>Corrigir dados incompletos, inexatos ou desatualizados</li>
                        <li className={styles.texto}>Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD</li>
                        <li className={styles.texto}>Solicitar a portabilidade dos dados a outro fornecedor de serviço ou produto</li>
                        <li className={styles.texto}>Eliminar dados pessoais tratados com seu consentimento</li>
                        <li className={styles.texto}>Obter informações sobre as entidades com as quais compartilhamos seus dados</li>
                        <li className={styles.texto}>Revogar o consentimento a qualquer momento</li>
                    </ul>

                <h3 className={styles.subtitulo}>6. Cookies</h3>
                <p className={styles.texto}>Utilizamos cookies para melhorar a funcionalidade do nosso site e personalizar sua experiência.
                Você pode configurar seu navegador para recusar cookies, mas isso pode afetar a disponibilidade de certas funcionalidades.</p>

                <h3 className={styles.subtitulo}>7. Alterações nesta Política de Privacidade</h3>
                <p className={styles.texto}>Podemos atualizar esta Política de Privacidade periodicamente.
                Notificaremos os usuários sobre quaisquer mudanças significativas e publicaremos a política revisada em nosso site.
                Recomendamos que você revise esta página regularmente para se manter informado sobre nossas práticas de privacidade.</p>

                <h3 className={styles.subtitulo}>8. Contato</h3>
                <p className={styles.texto}>Se você tiver qualquer dúvida ou solicitação relacionada a esta Política de Privacidade ou ao tratamento de seus dados pessoais, entre em contato conosco pelo e-mail <strong>contato@tamanhodastorcidas.com.br</strong>.</p>
                <br/>
                <hr/>
                <p className={styles.texto}>Ao utilizar nosso site, você concorda com os termos desta Política de Privacidade.</p>
                <p className={styles.texto}>Agradecemos por confiar no <strong>Tamanho das Torcidas</strong> e nos ajudar a construir uma comunidade de torcedores dedicada e verdadeira.</p>

                <p>Atualizada em: 01/06/2024</p>
            </span>

        </section>
    )
}

export default Politica;