import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styles from './Login.module.css';
import AlertMaterial from "../../AlertMaterial.jsx";
import Loading from "../Loading.jsx";
import Adsense from "../GoogleADS/Adsense.jsx";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import {POST_AUTH_FACEBOOK, POST_AUTH_GOOGLE, POST_AUTH_HOTMAIL} from "../../../api.jsx";
import useFetch from "../../../Requisicao.jsx";
import { GlobalContext } from "../../GlobalContext.jsx";
import { usuarioContext } from "../../UsuarioContext.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import Oauth from "./Oauth.jsx";

const msalConfig = {
    auth: {
        clientId: import.meta.env.VITE_ID_APP_OAUTH_HOTMAIL,
        authority: `https://login.microsoftonline.com/${import.meta.env.VITE_ID_DIRECTORY_OAUTH_HOTMAIL}`,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};

const Login = (isVisible) => {
    const anuncio = import.meta.env.VITE_ANUNCIO === 'true';
    const ID_CLIENTE_GOOGLE = import.meta.env.VITE_ID_CLIENTE_OAUTH_GOOGLE;

    const [loginVisivel, setLoginVisivel] = useState(false);
    const [oauth, setOauth] = useState(false);
    const [mensagemUsuario, setMensagemUsuario] = useState("");
    const [tipoMensagemUsuario, setTipoMensagemUsuario] = useState("");
    const [msalReady, setMsalReady] = useState(false);

    const { error, loading, request } = useFetch();
    const { dados, setDados } = React.useContext(GlobalContext);
    const { usuario, setUsuario, setLogado, logado, setLocaisInicial } = React.useContext(usuarioContext);

    const navigate = useNavigate();
    const msalInstanceRef = useRef(null);

    const salvarToken = (token) => {
        window.localStorage.setItem("token", token);
        navigate('/voto');
    };

    useEffect(() => {
        if (mensagemUsuario && tipoMensagemUsuario) {
            const timer = setTimeout(() => {
                setMensagemUsuario("");
                setTipoMensagemUsuario("");
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [mensagemUsuario, tipoMensagemUsuario]);

    useEffect(() => {
        setLoginVisivel(isVisible);
    }, [isVisible]);

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: import.meta.env.VITE_ID_CLIENTE_OAUTH_FACEBOOK,
                cookie: true,
                xfbml: true,
                version: 'v20.0'
            });

            window.FB.Event.subscribe('auth.statusChange', function(response) {
                if (response.status === 'connected') {
                    window.FB.api('/me', { fields: 'name,email,picture' }, function(userInfo) {
                        setUsuario({
                            name: userInfo.name,
                            email: userInfo.email,
                            picture: userInfo.picture.data.url
                        });
                        setLogado(true);
                    });
                } else {
                    setUsuario(null);
                    setLogado(false);
                }
            });
        };

        // Carrega o SDK do Facebook
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            js.onload = function() {
                if (window.FB) {
                    window.fbAsyncInit();
                }
            };
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    useEffect(() => {
        const initializeMsal = async () => {
            const instance = new PublicClientApplication(msalConfig);
            await instance.initialize();
            msalInstanceRef.current = instance;
            setMsalReady(true);
        };
        initializeMsal();
    }, []);

    const handleGoogleSuccess = async (res) => {
        const body = {
            credential: res.credential,
        };

        const { url, options } = POST_AUTH_GOOGLE(body);
        const { response, json } = await request(url, options);

        if (response.ok) {
            const { user, token, locais } = json;
            salvarToken(token);
            setUsuario(user);
            setLocaisInicial(locais);
            setLogado(true);
            setMensagemUsuario("Voto autenticado com sucesso.");
            setTipoMensagemUsuario("success");
        } else {
            setMensagemUsuario("Não foi possível realizar a autenticação.");
            setTipoMensagemUsuario("error");
            console.error("Erro ao autenticar o voto:", json ? json.message : 'Erro desconhecido');
        }
    };

    const handleGoogleFailure = (error) => {
        console.error(error);
    };

    const handleFacebookLogin = () => {
        window.FB.login((response) => {
            if (response.authResponse) {
                window.FB.api('/me', { fields: 'name,email,picture' }, async (userInfo) => {
                    const body = {
                        credential: userInfo,
                    };

                    const { url, options } = POST_AUTH_FACEBOOK(body);
                    const { response: serverResponse, json } = await request(url, options);

                    if (serverResponse.ok) {
                        const { user, token, locais } = json;
                        salvarToken(token);
                        setUsuario(user);
                        setLocaisInicial(locais);
                        setLogado(true);
                        setMensagemUsuario("Voto autenticado com sucesso.");
                        setTipoMensagemUsuario("success");
                    } else {
                        setMensagemUsuario("Não foi possível realizar a autenticação.");
                        setTipoMensagemUsuario("error");
                        console.error("Erro ao autenticar o voto:", json ? json.message : 'Erro desconhecido');
                    }
                });
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, { scope: 'email' });
    };

    const handleHotmailLogin = async () => {
        if (!msalReady || !msalInstanceRef.current) {
            console.error("MSAL is not initialized.");
            return;
        }
        try {
            const loginResponse = await msalInstanceRef.current.loginPopup({
                scopes: ["openid", "profile", "email"]
            });

            const { account } = loginResponse;

            const body = {
                credential: account,
            };

            const { url, options } = POST_AUTH_HOTMAIL(body);
            const { response, json } = await request(url, options);

            if (response.ok) {
                const { user, token, locais } = json;
                salvarToken(token);
                setUsuario(user);
                setLocaisInicial(locais);
                setLogado(true);
                setMensagemUsuario("Voto autenticado com sucesso.");
                setTipoMensagemUsuario("success");
            } else {
                setMensagemUsuario("Não foi possível realizar a autenticação.");
                setTipoMensagemUsuario("error");
                console.error("Erro ao autenticar o voto:", json ? json.message : 'Erro desconhecido');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLogout = () => {
        window.FB.logout(() => {
            setUsuario(null);
            setLogado(false);
        });
        msalInstanceRef.current.logoutPopup();
        setUsuario(null);
        setLogado(false);
    };

    const toogleExplicacaoOauth = () => {
        setOauth(!oauth);
    }

    return (
        <>
            {mensagemUsuario && tipoMensagemUsuario && (
                <AlertMaterial tipo={tipoMensagemUsuario} mensagem={mensagemUsuario}/>
            )}

            {loading && <Loading/>}

            <section className={`${styles.containerLogin} ${loginVisivel ? styles.show : styles.noShow}`}>
                <div className={styles.container}>

                    <div className={styles.key}>
                        <i className="fa-solid fa-key"></i>
                    </div>
                    <h1 className={styles.titulo}>
                        AUTENTICAR VOTO
                        <i className="fa-regular fa-circle-question"  onClick={toogleExplicacaoOauth}></i>
                    </h1>
                    <Helmet>
                    <script async defer crossOrigin="anonymous"
                                src="https://connect.facebook.net/en_US/sdk.js"></script>
                    </Helmet>
                    <div className={styles.login_page}>
                        {oauth && <Oauth/>}
                        <div className={styles.login_options}>

                            {!logado ? (
                                <>
                                    {/*<button className={styles.facebook} onClick={handleFacebookLogin} aria-label="Login com Facebook">*/}
                                    {/*    <div>*/}
                                    {/*        <img src={"img/fb.PNG"} alt="facebook"/>*/}
                                    {/*        <span>Fazer login com Facebook</span>*/}
                                    {/*    </div>*/}
                                    {/*</button>*/}
                                    <GoogleOAuthProvider clientId={ID_CLIENTE_GOOGLE}  aria-label="Login com Google">
                                        <GoogleLogin
                                            onSuccess={handleGoogleSuccess}
                                            onFailure={handleGoogleFailure}
                                        />
                                    </GoogleOAuthProvider>
                                    {/*<button className={styles.microsoft} onClick={handleHotmailLogin} aria-label="Login com Microsoft">*/}
                                    {/*    <div>*/}
                                    {/*        <img src={"img/microsoft.png"} alt="microsoft"/>*/}
                                    {/*        <span> Fazer login com Hotmail</span>*/}
                                    {/*    </div>*/}
                                    {/*</button>*/}
                                </>
                            ) : (
                                <div className={styles.userInfo}>
                                    <img src={usuario.picture} alt={usuario.name}/>
                                    <span>{usuario.name}</span>
                                    <button onClick={handleLogout} aria-label="sair">Sair</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {anuncio && <div className={styles.bannerPlub}>
                    <Adsense
                        client={import.meta.env.VITE_AD_CLIENT}
                        slot={import.meta.env.VITE_AD_SLOT}
                    />
                </div>}
            </section>
        </>
    );
};

export default Login;
