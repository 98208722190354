import styles from "./Info.module.css";
import LinkNav from "../LinkNav.jsx";
import ExternalLink from "../ExternalLink.jsx";

const Info = ({ width, height }) => {
    return (
        <>
            {width < 780 ? (
                <nav className={styles.info}>
                    <ul className={styles.linksRedesSociais}>
                        <ExternalLink
                            rota={"https://www.facebook.com/profile.php?id=61552015152862"}
                            nome={"Facebook"}
                        >
                            <i className="fab fa-facebook"></i>
                        </ExternalLink>
                        <ExternalLink
                            rota={"https://www.instagram.com/tamanhodastorcidas"}
                            nome={"Instagram"}
                        >
                            <i className="fab fa-instagram"></i>
                        </ExternalLink>
                        <ExternalLink
                            rota={"https://www.youtube.com/channel/UC-XvvztVFChm55ymtl9WJ9g"}
                            nome={"YouTube"}
                        >
                            <i className="fab fa-youtube"></i>
                        </ExternalLink>
                    </ul>
                    <nav className={styles.linksInfo}>
                        <ul className={styles.containerLinkInfo}>
                            <LinkNav rota={"/sobre"} classe={"linkInfo"}>
                                Sobre nós
                            </LinkNav>
                            <LinkNav rota={"/termos"} classe={"linkInfo"}>
                                Termos de uso
                            </LinkNav>
                        </ul>
                        <ul className={styles.containerLinkInfo}>
                            <LinkNav rota={"/politica"} classe={"linkInfo"}>
                                Política de Privacidade
                            </LinkNav>
                            <LinkNav rota={"/contato"} classe={"linkInfo"}>
                                Contato
                            </LinkNav>
                        </ul>
                    </nav>
                </nav>
            ) : (
                <nav className={styles.info}>
                    <nav className={styles.linksInfo}>
                        <ul className={styles.containerLinkInfo}>
                            <LinkNav rota={"/sobre"} classe={"linkInfo"}>
                                Sobre nós
                            </LinkNav>
                            <LinkNav rota={"/termos"} classe={"linkInfo"}>
                                Termos de uso
                            </LinkNav>
                        </ul>
                        <ul className={styles.containerLinkInfo}>
                            <LinkNav rota={"/politica"} classe={"linkInfo"}>
                                Política de Privacidade
                            </LinkNav>
                            <LinkNav rota={"/contato"} classe={"linkInfo"}>
                                Contato
                            </LinkNav>
                        </ul>
                    </nav>
                    <ul className={styles.linksRedesSociais}>
                        <ExternalLink
                            rota={"https://www.facebook.com/profile.php?id=61552015152862"}
                            nome={"Facebook"}
                        >
                            <i className="fab fa-facebook"></i>
                        </ExternalLink>
                        <ExternalLink
                            rota={"https://www.instagram.com/tamanhodastorcidas"}
                            nome={"Instagram"}
                        >
                            <i className="fab fa-instagram"></i>
                        </ExternalLink>
                        <ExternalLink
                            rota={"https://www.youtube.com/channel/UC-XvvztVFChm55ymtl9WJ9g"}
                            nome={"YouTube"}
                        >
                            <i className="fab fa-youtube"></i>
                        </ExternalLink>
                    </ul>
                </nav>
            )}
        </>
    );
};

export default Info;
