
export const API_URL = import.meta.env.VITE_API_URL;

/////////////////////////  TORCEDOR  //////////////////////////////

export function PATCH_TORCEDOR(body, idTorcedor) {
    return {
        url: API_URL + "/torcedor/voto/" + idTorcedor,
        options: {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    }
}

export function DELETE_TORCEDOR(idTorcedor) {
    return {
        url: API_URL + "/torcedor/" + idTorcedor,
        options: {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
        }
    }
}


/////////////////////////  AUTH  //////////////////////////////

export function POST_VALIDA_TOKEN(token) {
    return {
        url: API_URL + "/auth/valida_token",
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": token
            }
        }
    }
}

export function POST_LOGIN(body) {
    return {
        url: API_URL + "/auth",
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    }
}

export function POST_AUTH_GOOGLE(body) {
    return {
        url: API_URL + "/auth/google",
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    }
}

export function POST_AUTH_HOTMAIL(body) {
    return {
        url: API_URL + "/auth/hotmail",
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    }
}

export function POST_AUTH_FACEBOOK(body) {
    return {
        url: API_URL + "/auth/facebook",
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    }
}


//////////////////////// DADOS CLUBES ///////////////////////////


export function GET_CLUBES() {
    const url = API_URL + "/clubes";
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}

export function GET_CLUBES_CONSULTA() {
    const url = API_URL + "/clubes/consulta";
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}

export function GET_CLUBE(idClube) {
    const url = API_URL + "/clube?" + idClube;
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}

export function POST_CLUBES_PERSONALIZADO(body) {
    return {
        url: API_URL + "/clube/personalizado",
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    }
}

//////////////////////// LOCAIS ///////////////////////////


export function GET_PAISES() {
    const url = API_URL + "/local/paises";
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}

export function GET_REGIOES(idPais) {
    const url = API_URL + "/local/regioes/" + idPais;
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}

export function GET_ESTADOS(idRegiao) {
    const url = API_URL + "/local/estados/" + idRegiao;
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}

export function GET_CIDADES(idEstado) {
    const url = API_URL + "/local/cidades/" + idEstado;
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}

export function GET_LOCAIS_FILTRO(tipoContagem) {
    const url = API_URL + "/local/clubes?" + tipoContagem;
    return {
        url: url,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }
    }
}