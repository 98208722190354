import styles from './Filtros.module.css';
import Adsense from "../GoogleADS/Adsense.jsx";
import React, {useContext} from "react";
import Loading from "../Loading.jsx";
import AlertMaterial from "../../AlertMaterial.jsx";
import { RadioGroup, FormControlLabel, Radio, FormGroup } from '@mui/material'; // ou @mui/material se estiver usando o MUI v5
import { Checkbox, Switch, TreeSelect } from 'antd';
import {useLocation} from "react-router-dom";
import {GlobalContext} from "../../GlobalContext.jsx";
import {GET_CIDADES, GET_LOCAIS_FILTRO} from "../../../api.jsx";
import useFetch from "../../../Requisicao.jsx";


const Filtros = () => {

    const anuncio = import.meta.env.VITE_ANUNCIO === 'true';

    const { dados, setDados } = useContext(GlobalContext);

    const [filtroVisivel, setFiltroVisivel] = React.useState(false);

    const [mensagemUsuario, setMensagemUsuario] = React.useState("");
    const [tipoMensagemUsuario, setTipoMensagemUsuario] = React.useState("");

    const FAIXAS_ETARIAS = ['0_17', '18_29', '30_39', '40_49', '50_59', '60_plus'];

    const [sexo, setSexo] = React.useState(dados.sexo || { M: true, F: true });
    const [tipoRankingFiltro, setTipoRankingFiltro] = React.useState(dados.tipoRankingFiltro || 'amada');
    const [faixasEtarias, setFaixasEtarias] = React.useState(dados.faixasEtarias || FAIXAS_ETARIAS);
    const [localClubes, setLocalClubes] = React.useState(dados.localClubes || ['pais_66636ef11a35967c1d40c7bd']);
    const [localTorcedores, setLocalTorcedores] = React.useState(dados.localTorcedores || ['pais_66636ef11a35967c1d40c7bd', 'pais_66636ef11a35967c1d40dd9b']);
    const [treeDataClubes, setTreeDataClubes] = React.useState([]);
    const [treeDataTorcida, setTreeDataTorcida] = React.useState([]);

    const { error, loading, request } = useFetch();
    const location = useLocation();

    const extractAllValues = (treeData) => {
        let values = [];
        const traverse = (nodes) => {
            nodes.forEach(node => {
                values.push(node.value);
                if (node.children) {
                    traverse(node.children);
                }
            });
        };
        traverse(treeData);
        return values;
    };

    React.useEffect(() => {
        const allValuesClubes = extractAllValues(treeDataClubes);
        const updatedLocalClubes = localClubes.some(value => allValuesClubes.includes(value)) ? localClubes : allValuesClubes;
        const allValuesTorcidas = extractAllValues(treeDataTorcida);
        const updatedLocalTorcidas = localTorcedores.some(value => allValuesTorcidas.includes(value)) ? localTorcedores : allValuesTorcidas;

        setDados(prevState => ({
            ...prevState,
            sexo,
            tipoRankingFiltro,
            faixasEtarias,
            localClubes: updatedLocalClubes,
            localTorcedores: updatedLocalTorcidas
        }));
    }, [sexo, tipoRankingFiltro, faixasEtarias, localClubes, localTorcedores, treeDataClubes, treeDataTorcida]);

    React.useEffect(() => {
        if(location.pathname === '/filtros'){
            setFiltroVisivel(true);
        }
    }, [location.pathname]); // Dependências do useEffect

    React.useEffect(() => {
       consultaLocais()
    }, [tipoRankingFiltro]); // Dependências do useEffect

    const handleSexoChange = (checked, name) => {
        setSexo({ ...sexo, [name]: checked });
    };

    const handleTipoRankingChange = (event) => {
        setTipoRankingFiltro(event.target.value);
    };

    const handleFaixasEtariasChange = (checked, faixa) => {
        setFaixasEtarias(prev =>
            checked ? [...prev, faixa] : prev.filter(item => item !== faixa)
        );
    };


    const consultaLocais = async () => {

        const { url, options } = GET_LOCAIS_FILTRO(tipoRankingFiltro);
        const { response, json } = await request(url, options);

        if (response.ok) {
            const {locaisClubes, locaisTorcedores} = json;
            setTreeDataClubes(locaisClubes);
            setTreeDataTorcida(locaisTorcedores);
            setLocalClubes(prevLocalClubes => {
                const allValuesClubes = extractAllValues(locaisClubes);
                return prevLocalClubes.some(value => allValuesClubes.includes(value)) ? prevLocalClubes : allValuesClubes;
            });
            setLocalTorcedores(prevLocalTorcedores => {
                const allValuesTorcidas = extractAllValues(locaisTorcedores);
                return prevLocalTorcedores.some(value => allValuesTorcidas.includes(value)) ? prevLocalTorcedores : allValuesTorcidas;
            });

        } else {
            setMensagemUsuario("Não foi possível carregar as cidades.");
            setTipoMensagemUsuario("error");
        }
    };


    React.useEffect(() => {
        // Se houver uma mensagem para o usuário e um tipo de mensagem definidos
        if (mensagemUsuario && tipoMensagemUsuario) {
            // Defina um timer para limpar a mensagem após um breve intervalo
            const timer = setTimeout(() => {
                setMensagemUsuario("");
                setTipoMensagemUsuario("");
            }, 5000); // Ajuste o tempo conforme necessário

            // Limpeza do timer caso o componente seja desmontado antes do timer ser concluído
            return () => clearTimeout(timer);
        }
    }, [mensagemUsuario, tipoMensagemUsuario]); // Dependências do useEffect

    return (
        <>
            {mensagemUsuario && tipoMensagemUsuario && (
                <AlertMaterial tipo={tipoMensagemUsuario} mensagem={mensagemUsuario} />
            )}

            {loading && <Loading />}

            <section className={`${styles.containerFilter} ${filtroVisivel ? styles.show : styles.noShow}`}>
                <h1 className={styles.titulo}>
                    <span>FILTROS</span>
                </h1>

                <div className={styles.dados}>
                    <h2 className={styles.subtitulo}>
                        <span>TIPO RANKING</span>
                    </h2>
                    <div className={styles.containerTipoRanking}>
                        <RadioGroup row value={tipoRankingFiltro} onChange={handleTipoRankingChange} required>
                            <FormControlLabel
                                value="amada"
                                control={<Radio/>}
                                label={
                                    <>
                                        +AMADA <i className="fa-solid fa-heart" style={{color: 'red'}}></i>
                                    </>
                                }
                            />
                            <FormControlLabel
                                value="simpatizada"
                                control={<Radio/>}
                                label={
                                    <>
                                        +SIMPATIZADA <i className="fa-solid fa-face-smile"
                                                        style={{color: '#f6d70a'}}></i>
                                    </>
                                }
                            />
                            <FormControlLabel
                                value="odiada"
                                control={<Radio/>}
                                label={
                                    <>
                                        +ODIADA <i className="fa-solid fa-face-angry" style={{color: 'purple'}}></i>
                                    </>
                                }
                            />
                        </RadioGroup>
                    </div>

                    <h2 className={styles.subtitulo}>
                        <span>GÊNERO</span>
                    </h2>

                    <div className={styles.containerBTNGenero}>
                        <Switch
                            checked={sexo.M}
                            onChange={(checked) => handleSexoChange(checked, 'M')}
                            className={'switchFiltroGenero'}
                            checkedChildren="MASCULINO"
                            unCheckedChildren="MASCULINO"
                            name="M"
                        />
                        <Switch
                            checked={sexo.F}
                            onChange={(checked) => handleSexoChange(checked, 'F')}
                            className={'switchFiltroGenero'}
                            checkedChildren="FEMININO"
                            unCheckedChildren="FEMININO"
                            name="F"
                        />
                    </div>

                    <h2 className={styles.subtitulo}>
                        <span>FAIXA ETÁRIA</span>
                    </h2>

                    <div className={styles.containerFaixaEtaria}>
                        <FormGroup row>
                            {['0_17', '18_29', '30_39', '40_49', '50_59', '60_plus'].map((faixa) => (
                                <FormControlLabel
                                    key={faixa}
                                    control={
                                        <Checkbox
                                            checked={faixasEtarias.includes(faixa)}
                                            onChange={(event) => handleFaixasEtariasChange(event.target.checked, faixa)}
                                            name={faixa}
                                            style={{marginLeft: '10px'}}
                                        />
                                    }
                                    label={faixa.replace('_', '-').replace('-plus', '+')}
                                />
                            ))}
                        </FormGroup>
                    </div>

                    <h2 className={styles.subtitulo}>
                        <span>LOCAIS DOS CLUBES</span>
                    </h2>

                    <div className={styles.containerLocais}>
                        <TreeSelect
                            style={{width: '90%', margin: 'auto'}}
                            value={localClubes}
                            treeCheckable={true}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            placeholder="Selecione os locais dos clubes"
                            treeData={treeDataClubes}
                            onChange={setLocalClubes}
                        />
                    </div>

                    <h2 className={styles.subtitulo}>
                        <span>LOCAIS DAS TORCIDAS</span>
                    </h2>

                    <div className={styles.containerLocais}>
                        <TreeSelect
                            style={{width: '90%', margin: 'auto'}}
                            value={localTorcedores}
                            treeCheckable={true}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            placeholder="Selecione os locais das torcidas"
                            treeData={treeDataTorcida}
                            onChange={setLocalTorcedores}
                        />
                    </div>

                    <p>Aplique o filtro: clicando no botão RANKING.</p>
                </div>

            </section>

            {anuncio && (
                <div className={styles.bannerPlub}>
                    <Adsense
                        client={import.meta.env.VITE_AD_CLIENT}
                        slot={import.meta.env.VITE_AD_SLOT}
                    />
                </div>
            )}

        </>
    );
}

export default Filtros;
