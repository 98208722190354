import styles from "./Ranking.module.css";
import React from "react";
import { GlobalContext } from "../../GlobalContext.jsx";
import {GET_CLUBES, POST_CLUBES_PERSONALIZADO} from "../../../api.jsx";
import useFetch from "../../../Requisicao.jsx";
import AlertMaterial from "../../AlertMaterial.jsx";
import Adsense from "../GoogleADS/Adsense.jsx";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading.jsx";

const Ranking = ({ tipoRanking = 'amada', width, height }) => {
    const anuncio = import.meta.env.VITE_ANUNCIO === 'true';
    const navigate = useNavigate();

    const [mensagemUsuario, setMensagemUsuario] = React.useState("");
    const [tipoMensagemUsuario, setTipoMensagemUsuario] = React.useState("");

    const { error, loading, request } = useFetch();
    const { dados, setDados } = React.useContext(GlobalContext);

    const [nomeRanking, setNomeRanking] = React.useState("");
    const [clubes, setClubes] = React.useState([]);
    const [clubesOrdenados, setClubesOrdenados] = React.useState([]);
    const [filteredClubes, setFilteredClubes] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [maxValor, setMaxValor] = React.useState(1);


    const buscarClubes = async () => {
        const { url, options } = GET_CLUBES();
        const { response, json } = await request(url, options);

        if (response.ok) {
            // Ordenar e definir a posição geral baseada na torcida amada
            const clubesAmadosOrdenados = [...json].sort((a, b) => b.contagemAmada - a.contagemAmada);
            const clubesComPosicaoGeral = clubesAmadosOrdenados.map((clube, index) => ({
                ...clube,
                posicaoGeral: index + 1,
            }));

            setClubes(clubesComPosicaoGeral);

            // Ordenar baseado no tipoRanking atual
            ordenarClubes(clubesComPosicaoGeral, tipoRanking);


        } else {
            setMensagemUsuario("Não foi possível calcular os votos.");
            setTipoMensagemUsuario("error");
            console.error("Erro ao calcular os votos:", json ? json.message : 'Erro desconhecido');
        }
    };


    const rankingPesonalizado = async () => {

        const body = {
            filters: {
                sexo: dados.sexo || {M: false, F: false},
                tipoRankingFiltro: dados.tipoRankingFiltro || 'amada',
                faixasEtarias: dados.faixasEtarias || [],
                localClubes: dados.localClubes || [],
                localTorcedores: dados.localTorcedores || [],
            }
        }

        const { url, options } = POST_CLUBES_PERSONALIZADO(body);
        const { response, json } = await request(url, options);

        if (response.ok) {
            // Mapear a posicaoGeral dos clubes personalizados com base no clubes gerais
            const clubesPersonalizadosComPosicao = json.map(clubePersonalizado => {
                const clubeGeral = clubes.find(c => c.idClube === clubePersonalizado.idClube);
                return {
                    ...clubePersonalizado,
                    posicaoGeral: clubeGeral ? clubeGeral.posicaoGeral : null,
                };
            });

            // Ordenar baseado no tipoRanking atual
            ordenarClubes(clubesPersonalizadosComPosicao, tipoRanking);
        } else {
            setMensagemUsuario("Não foi possível calcular o ranking personalizado.");
            setTipoMensagemUsuario("error");
            console.error("Erro ao calcular os votos:", json ? json.message : 'Erro desconhecido');
        }
    }

    React.useEffect(() => {
        buscarClubes();
    }, []);

    React.useEffect(() => {
        if(tipoRanking === 'personalizado'){
            rankingPesonalizado();
        } else {
            ordenarClubes(clubes, tipoRanking);
        }
    }, [tipoRanking, clubes, dados]);

    const formatNumber = (num) => {
        if (num === undefined || num === null) {
            return '0';
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const ordenarClubes = (clubes, tipoRanking) => {
        let clubesOrdenados = [...clubes];
        let maxValorReferencia = 1; // Valor inicial padrão

        switch (tipoRanking) {
            case 'amada':
                setNomeRanking(<>TORCIDAS +AMADAS <i className="fa-solid fa-heart" style={{ color: 'red' }}></i></>);
                clubesOrdenados = clubesOrdenados.filter(clube => clube.contagemAmada > 0).sort((a, b) => b.contagemAmada - a.contagemAmada);
                maxValorReferencia = clubesOrdenados[0]?.contagemAmada || 1;
                break;
            case 'simpatizada':
                setNomeRanking(<>TORCIDAS +SIMPATIZADAS <i className="fa-solid fa-face-smile" style={{color: "#f6d70a"}}></i></>);
                clubesOrdenados = clubesOrdenados.filter(clube => clube.contagemSimpatizada > 0).sort((a, b) => b.contagemSimpatizada - a.contagemSimpatizada);
                maxValorReferencia = clubesOrdenados[0]?.contagemSimpatizada || 1;
                break;
            case 'odiada':
                setNomeRanking(<>TORCIDAS +ODIADAS <i className="fa-solid fa-face-angry" style={{color: 'purple'}}></i></>);
                clubesOrdenados = clubesOrdenados.filter(clube => clube.contagemOdiada > 0).sort((a, b) => b.contagemOdiada - a.contagemOdiada);
                maxValorReferencia = clubesOrdenados[0]?.contagemOdiada || 1;
                break;
            case 'experiente':
                setNomeRanking(<>TORCIDAS +EXPERIENTES <i className="fa-solid fa-clock" style={{color: 'grey'}}></i></>);
                clubesOrdenados = clubesOrdenados.filter(clube => clube.contagemAmada > 0).sort((a, b) => (b.idadesAcumuladas / b.contagemAmada) - (a.idadesAcumuladas / a.contagemAmada));
                maxValorReferencia = clubesOrdenados[0]?.idadesAcumuladas / clubesOrdenados[0]?.contagemAmada || 1;
                break;
            case 'masculina':
                setNomeRanking(<>TORCIDAS +MASCULINAS <i className="fa-solid fa-mars" style={{color: 'blue'}}></i></>);
                clubesOrdenados = clubesOrdenados.filter(clube => clube.sexo.M > 0).sort((a, b) => b.sexo.M - a.sexo.M);
                maxValorReferencia = clubesOrdenados[0]?.sexo.M || 1;
                break;
            case 'feminina':
                setNomeRanking(<>TORCIDAS +FEMININAS <i className="fa-solid fa-venus" style={{color: 'pink'}}></i></>);
                clubesOrdenados = clubesOrdenados.filter(clube => clube.sexo.F > 0).sort((a, b) => b.sexo.F - a.sexo.F);
                maxValorReferencia = clubesOrdenados[0]?.sexo.F || 1;
                break;
            case 'personalizado':
                setNomeRanking(<>RANKING PERSONALIZADO <i className="fa-solid fa-sliders" style={{color: 'grey'}}></i></>);
                clubesOrdenados = clubesOrdenados.filter(clube => clube.total > 0).sort((a, b) => b.total - a.total);
                maxValorReferencia = clubesOrdenados[0]?.total || 1;
                break;
        }

        // Atualizar a posição dos clubes após a ordenação
        clubesOrdenados = clubesOrdenados.map((clube, index) => ({
            ...clube,
            posicao: index + 1,
        }));

        setClubesOrdenados(clubesOrdenados);
        setFilteredClubes(clubesOrdenados); // Atualiza clubes filtrados
        setMaxValor(maxValorReferencia); // Atualiza o valor máximo de referência
    };

    // Função para remover acentos
    const removeAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };


    React.useEffect(() => {
        const termosPesquisa = searchTerm.toLowerCase().split(" ");
        const resultadosFiltrados = clubesOrdenados.filter(clube =>
            termosPesquisa.some(termo => removeAcentos(clube.nomeClube.toLowerCase()).includes(removeAcentos(termo)))
        );
        setFilteredClubes(resultadosFiltrados);
        setCurrentPage(1); // Reset to the first page when the search term changes
    }, [searchTerm, clubesOrdenados]);

    const getTotalTorcedores = () => {
        switch (tipoRanking) {
            case 'amada':
                return filteredClubes.reduce((acc, clube) => acc + (clube.contagemAmada || 0), 0);
            case 'simpatizada':
                return filteredClubes.reduce((acc, clube) => acc + (clube.contagemSimpatizada || 0), 0);
            case 'odiada':
                return filteredClubes.reduce((acc, clube) => acc + (clube.contagemOdiada || 0), 0);
            case 'masculina':
                return filteredClubes.reduce((acc, clube) => acc + (clube.sexo.M || 0), 0);
            case 'feminina':
                return filteredClubes.reduce((acc, clube) => acc + (clube.sexo.F || 0), 0);
            case 'experiente':
                return filteredClubes.reduce((acc, clube) => acc + (clube.idadesAcumuladas / clube.contagemAmada || 0), 0);
            case 'personalizado':
                return filteredClubes.reduce((acc, clube) => acc + (clube.total || 0), 0);
            default:
                return 0;
        }
    };

    const totalTorcedores = getTotalTorcedores();

    const handleImageError = (e) => {
        e.target.src = '/img/clubes/000.webp';
    };

    // Pagination Logic
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to the first page when items per page change
    };

    const handlePageChange = (direction) => {
        setCurrentPage(prevPage => prevPage + direction);
    };

    const goToFirstPage = () => {
        setCurrentPage(1);
    };

    const goToLastPage = () => {
        setCurrentPage(totalPages);
    };

    const totalPages = Math.ceil(filteredClubes.length / itemsPerPage);
    const currentClubes = filteredClubes.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleClubeDetalhe = (idClube, posicao) => {
        navigate('/clube?' + idClube + '&&pos=' + posicao);
    };

    React.useEffect(() => {
        // Se houver uma mensagem para o usuário e um tipo de mensagem definidos
        if (mensagemUsuario && tipoMensagemUsuario) {
            // Defina um timer para limpar a mensagem após um breve intervalo
            const timer = setTimeout(() => {
                setMensagemUsuario("");
                setTipoMensagemUsuario("");
            }, 5000); // Ajuste o tempo conforme necessário

            // Limpeza do timer caso o componente seja desmontado antes do timer ser concluído
            return () => clearTimeout(timer);
        }
    }, [mensagemUsuario, tipoMensagemUsuario]); // Dependências do useEffect


    return (
        <>
            {mensagemUsuario && tipoMensagemUsuario && (
                <AlertMaterial tipo={tipoMensagemUsuario} mensagem={mensagemUsuario} />
            )}

            {loading && <Loading/>}

            <section className={styles.rankingSection}>
                <header className={styles.containerTitulo}>
                    <h1 className={styles.title}>{nomeRanking}</h1>
                    <label htmlFor="search" className={styles.visually_hidden}></label>
                    <div className={styles.pesquisa}>
                        <input
                            id="search"
                            type="text"
                            value={searchTerm}
                            autoComplete="off"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Pesquisar clubes..."
                        />
                    </div>
                </header>


                <article className={styles.table}>
                    <header className={styles.header}>
                        <div className={styles.posicao}>
                            <span className={styles.cabecalho}>POS.</span>
                        </div>
                        <div className={styles.clube}>
                            <span className={styles.cabecalho}>/CLUBE</span>
                        </div>
                        <div className={styles.torcedores}>
                            <span className={styles.cabecalho}>{tipoRanking === 'feminina' ? "TORCEDORAS" : tipoRanking === 'experiente' ? "IDADE MÉDIA" : "TORCEDORES"}</span>
                        </div>
                    </header>

                    <div className={styles.tableBody}>
                        {currentClubes.length === 0 ? (tipoRanking === 'personalizado' ?
                            (<p className={styles.noClubes}>Não há clubes para o filtro aplicado.</p>)
                         : (<p className={styles.noClubes}>Não há clubes para essa categoria</p>)) : (
                            currentClubes.map((clube, index) => {
                                let torcedores;
                                switch (tipoRanking) {
                                    case 'amada':
                                        torcedores = clube.contagemAmada;
                                        break;
                                    case 'simpatizada':
                                        torcedores = clube.contagemSimpatizada;
                                        break;
                                    case 'odiada':
                                        torcedores = clube.contagemOdiada;
                                        break;
                                    case 'masculina':
                                        torcedores = clube.sexo.M;
                                        break;
                                    case 'feminina':
                                        torcedores = clube.sexo.F;
                                        break;
                                    case 'experiente':
                                        torcedores = (clube.idadesAcumuladas / clube.contagemAmada).toFixed(2);
                                        break;
                                    case 'personalizado':
                                        torcedores = clube.total;
                                        break;
                                    default:
                                        torcedores = 0;
                                }
                                const progressBarWidth = (torcedores / maxValor) * 100;
                                const percentage = ((torcedores / totalTorcedores) * 100).toFixed(2);
                                const progressBarClass = progressBarWidth < 25 ? `${styles.progressBar} ${styles.small}` : styles.progressBar;
                                const progressBarText = `${formatNumber(torcedores)} (${percentage}%)`;

                                return (
                                    <React.Fragment key={clube._id}>
                                        <div className={styles.row} onClick={() => handleClubeDetalhe(clube._id, clube.posicaoGeral)}>
                                            <div className={styles.posicao}>
                                                <span>{clube.posicao}º</span>
                                            </div>
                                            <img
                                                src={`/img/clubes/${clube.idClube}.webp`}
                                                className={styles.logoRanking}
                                                onError={handleImageError}
                                                alt={`${clube.nomeClube} logo`}
                                            />
                                            <div className={styles.clube}>
                                                <span>{clube.nomeClube}</span>
                                            </div>
                                            <div className={styles.progressBarContainer}>
                                                <div className={progressBarClass} style={{ width: `${progressBarWidth}%` }}>
                                                    {progressBarWidth >= 25 ? progressBarText : ''}
                                                </div>
                                                {progressBarWidth < 25 && <span className={styles.progressBarText}
                                                                                style={{ left: `${progressBarWidth}%` }}>{progressBarText}</span>}
                                            </div>
                                        </div>
                                        {(index + 1) % 21 === 0 && index + 1 < filteredClubes.length && width <= 1040 && anuncio && (
                                            <div className={styles.bannerPlub}>
                                                <Adsense
                                                    client={import.meta.env.VITE_AD_CLIENT}
                                                    slot={import.meta.env.VITE_AD_SLOT}
                                                    style={{ maxHeight: '90px' }}
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })
                        )}
                    </div>
                </article>

                <nav className={styles.ContainerNavegacaoPaginas}>
                    <div className={styles.controls}>
                        <div className={styles.paginacao}>
                            <button onClick={goToFirstPage} disabled={currentPage === 1} aria-label="Primeira página"><i
                                className="fa-solid fa-backward-step"></i></button>
                            <button onClick={() => handlePageChange(-1)} disabled={currentPage === 1} aria-label="Página anterior"><i
                                className="fa-solid fa-caret-left"></i></button>
                            <span>Página {currentPage} de {totalPages}</span>
                            <button onClick={() => handlePageChange(1)} disabled={currentPage === totalPages} aria-label="Próxima página"><i
                                className="fa-solid fa-caret-right"></i>
                            </button>
                            <button onClick={goToLastPage} disabled={currentPage === totalPages} aria-label="Última página"><i
                                className="fa-solid fa-forward-step"></i></button>
                        </div>

                        <label htmlFor="itemsPerPage">Clubes por página:</label>
                        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={80}>80</option>
                            <option value={160}>160</option>
                            <option value={320}>320</option>
                            <option value={filteredClubes.length}>TODOS</option>
                        </select>
                    </div>
                </nav>

                {width < 1040 ? anuncio && <div className={styles.bannerPlub}>
                    <Adsense
                        client={import.meta.env.VITE_AD_CLIENT}
                        slot={import.meta.env.VITE_AD_SLOT}
                    />
                </div> : ''}

            </section>
        </>
    );
};

export default Ranking;
