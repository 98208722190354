import React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    React.useEffect(() => {
        const topElement = document.getElementById('top');
        if (topElement) {
            topElement.scrollIntoView({
                behavior: 'smooth'
            });
        }
        // else {
        //     window.scrollTo({
        //         top: 0,
        //         behavior: 'smooth'
        //     });
        // }
    }, [pathname]);

    return null;
};

export default ScrollToTop;
